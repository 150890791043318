/* eslint-disable react/prop-types */
import Label from 'components/Input/Label';
import clsx from 'libs/clsx';

/**
 * @todo mendeklarasikan proptypes
 */
function Select({
  id,
  value,
  label,
  disabled,
  error,
  options,
  className,
  ...props
}) {
  return (
    <div>
      <Label htmlFor="id" error={error}>
        {label}
      </Label>
      <select
        value={value}
        id={id}
        className={clsx(
          'bg-white text-sm appearance-none border-2 border-gray-200 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-500',
          error && 'border-red-500 bg-red-100',
          disabled && 'bg-gray-100 cursor-not-allowed',
          className
        )}
        {...props}
      >
        {options.map((option, key) => (
          <option key={key} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>

      {error && <p className="text-red-500 text-xs italic mt-1">{error}</p>}
    </div>
  );
}

export default Select;
