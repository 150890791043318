/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import { forwardRef, memo, useCallback } from 'react';
import { MathJax } from 'better-react-mathjax';

import clsx from 'libs/clsx';
import { getAlphabetByIndex } from 'utils/exam';
import useBaseURL from 'hooks/useBaseURL';
import Typography from 'components/Typography';

function ExamScale(
  { question: { choices }, answer, onAnswerChange },
  ref // eslint-disable-line no-unused-vars
) {
  const path = useBaseURL();

  // Menangani apabila peserta menjawab atau mengubah jawaban.
  const handleChange = useCallback(
    (choice) => {
      const isEdited = answer !== choice;

      if (isEdited) onAnswerChange(choice);
    },
    [answer, onAnswerChange]
  );

  return (
    <>
      {choices.map((choice, index) => {
        const choiceId = `choice-${index}`;
        const choiceAlphabet = getAlphabetByIndex(index);
        const isSelected = answer === choiceAlphabet;
        const questionArabic = /[\u0600-\u06FF]/.test(choice);
        const choiceText = choice.dsk;

        const tempChoiceString = choiceText.replace(
          /@url\//g,
          `${path.questionImage}c/`
        );

        return (
          <div
            key={index}
            className={clsx(
              'answer-container',
              'flex items-center border-2 border-gray-100 rounded-lg p-2.5 my-3 shadow cursor-pointer select-none',
              isSelected && 'border-2 border-green-400'
            )}
            onClick={() => handleChange(choiceAlphabet)}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
          >
            <input
              type="radio"
              id={choiceId}
              name="choice"
              value={choiceAlphabet}
              onChange={(e) => handleChange(e.target.value)}
              onClick={(e) => e.stopPropagation()}
              checked={isSelected}
              className="h-4 w-4 text-gray-700 px-3 py-3 border rounded mr-2"
            />
            <label
              htmlFor={choiceId}
              className="cursor-pointer ml-1"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex items-center gap-2">
                <Typography className="font-normal">
                  {choiceAlphabet}.
                </Typography>
                <Typography
                  as="div"
                  variant={questionArabic ? 'heading3' : 'paragraph'}
                  className="font-normal"
                  style={{
                    fontFamily: questionArabic
                      ? 'Traditional Arabic'
                      : 'inherit',
                  }}
                >
                  <MathJax>
                    <span
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: tempChoiceString }}
                    />
                  </MathJax>
                </Typography>
              </div>
            </label>
          </div>
        );
      })}

      {/* Mengubah warna dari radio button */}
      <style jsx="true">{`
        input[type='radio'] {
          visibility: hidden; /* hide default radio button */
          /* you may need to adjust margin here, too */
        }
        input[type='radio']::before {
          /* create pseudoelement */
          border: 1px solid rgba(16, 185, 129, 1); /* thickness, style, color */
          height: 0.9em; /* height adjusts with font */
          width: 0.9em; /* width adjusts with font */
          border-radius: 50%; /* make it round */
          display: block; /* or flex or inline-block */
          content: ' '; /* won't display without this */
          cursor: pointer; /* appears clickable to mouse users */
          visibility: visible; /* reverse the 'hidden' above */
        }

        input[type='radio']:checked::before {
          /* selected */
          /* add middle dot when selected */
          /* slightly bigger second value makes it smooth */
          /* even more (e.g., 20% 50%) would make it fuzzy */
          background: radial-gradient(
            rgba(16, 185, 129, 1) 50%,
            transparent 0%
          );
        }
      `}</style>
    </>
  );
}

ExamScale = forwardRef(ExamScale);

export default memo(ExamScale);
