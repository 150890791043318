/**
 * Convert seconds to hours minutes and seconds.
 * @param {Number} timeSeconds
 * @returns {String[]}
 */
export function secondsToTime(timeSeconds) {
  const timeString = new Date(timeSeconds * 1000).toISOString().substr(11, 8);
  const [hours, minutes, seconds] = timeString.split(':');

  return [parseInt(hours, 10), parseInt(minutes, 10), parseInt(seconds, 10)];
}

/**
 * Convert seconds to sting `{hours} Jam {minutes} Menit {seconds} Detik`.
 * @param {Number} timeSeconds
 * @returns {String[]}
 */
export function secondsToText(timeSeconds) {
  const [hours, minutes, seconds] = secondsToTime(timeSeconds);

  let timeString = '';

  if (hours > 0) timeString += `${hours} Jam `;
  if (minutes > 0) timeString += `${minutes} Menit `;
  if (seconds > 0) timeString += `${seconds} Detik`;

  return timeString;
}

/**
 * Convert seconds to string `{hours}:{minutes}:{seconds}`.
 * @param {Number} timeSeconds
 * @returns {String[]}
 */
export function secondsToTimerText(timeSeconds) {
  const [hours, minutes, seconds] = secondsToTime(timeSeconds);
  // eslint-disable-next-line prettier/prettier
  return `${hours < 10 ? '0' : ''}${hours}:${
    minutes < 10 ? '0' : ''
  }${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
}
