import propTypes from 'prop-types';

import clsx from 'libs/clsx';

function Label({ htmlFor, error, children }) {
  return (
    <label
      className={clsx(
        'block text-gray-400 xl:text-base text-sm font-light mb-2',
        error && 'text-red-500'
      )}
      htmlFor={htmlFor}
    >
      {children}
    </label>
  );
}

Label.defaultProps = {
  error: null,
};

Label.propTypes = {
  htmlFor: propTypes.string.isRequired,
  error: propTypes.string,
  children: propTypes.node.isRequired,
};

export default Label;
