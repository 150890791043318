import {
  SET_CAMERA_ID,
  SET_BACKEND_URL,
  UNSET_BACKEND_URL,
  SET_CONFIG,
  TOGGLE_SIDEBAR,
  MODAL_IMAGE_OPEN,
  MODAL_IMAGE_CLOSE,
} from 'constant';

export const setCameraId = (cameraId) => ({
  type: SET_CAMERA_ID,
  payload: cameraId,
});

export const setBackendURL = (backendURL) => ({
  type: SET_BACKEND_URL,
  payload: backendURL,
});

export const unsetBackendURL = () => ({
  type: UNSET_BACKEND_URL,
});

export const setConfig = (config) => ({
  type: SET_CONFIG,
  payload: config,
});

export const toggleSidebar = () => ({
  type: TOGGLE_SIDEBAR,
});

export const openModalImage = (url) => ({
  type: MODAL_IMAGE_OPEN,
  payload: url,
});

export const closeModalImage = () => ({
  type: MODAL_IMAGE_CLOSE,
});
