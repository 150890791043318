/* eslint-disable react/prop-types */
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import SortableItem from './SortableItem';

/**
 * @description
 * - value adalah nilai yang akan dijadikan jawaban.
 * - label adalah isi dari pilihan.
 */
function ExamSort(
  { question: { id, choices }, answer, onAnswerChange },
  ref // eslint-disable-line no-unused-vars
) {
  // Nilainya akan bertambah saat peserta melakukan aksi drag.
  const [dragIncrement, setDragIncrement] = useState(0);

  // Indikator angka yang digunakan untuk menentukan isi dari jawaban.
  const originalValues = useMemo(
    () =>
      new Array(choices.length)
        .fill(0)
        .map((_, index) => (index + 1).toString()),
    [choices]
  );

  // Text dari setiap pilihan secara default.
  const originalLabels = useMemo(() => choices, [choices]);

  // Menampung jawaban dari proses render yang pertama kali (di soal yang sama).
  // Bertujuan untuk mengubah urutan list pada saat soal dimuat.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const storedAnswer = useMemo(() => answer, [id]);

  // Value yang sudah disorting.
  const [values, setValues] = useState([]);

  // Mendapatkan string jawaban baru.
  const getAnswer = useCallback(() => values.join(''), [values]);

  const isEdited = answer !== getAnswer();

  // Menangani perubahan secara urutan list secara otomatis pada saat soal ini
  // dimuat.
  useEffect(() => {
    if (originalValues.length === 0 || originalLabels.length === 0) {
      return;
    }

    // Melakukan sorting ulang untuk menyesuaikan list yang sudah diubah
    // urutannya.
    setValues(storedAnswer.split(''));
    // Merender sekali saat soal berubah.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Jika jawaban kosong, maka ubah urutan list seperti semula.
  useEffect(() => {
    if (answer === ' ') setValues(originalValues);
  }, [answer, originalValues]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = useCallback((event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setValues((currentItems) => {
        const oldIndex = currentItems.indexOf(active.id);
        const newIndex = currentItems.indexOf(over.id);

        return arrayMove(currentItems, oldIndex, newIndex);
      });

      setDragIncrement((current) => current + 1);
    }
  }, []);

  // Mengupdate jawaban ketika terjadi aksi drag.
  useEffect(() => {
    const newAnswer = getAnswer();

    if (newAnswer.length !== 0 && answer !== newAnswer) {
      onAnswerChange(newAnswer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dragIncrement]);

  useImperativeHandle(
    ref,
    () => ({
      componentWillUnmount: () => {
        if (isEdited) onAnswerChange(getAnswer());
      },
    }),
    [getAnswer, isEdited, onAnswerChange]
  );

  // Menangani munculnya error pada saat berpindah nomor soal karena perbedaan
  // panjang jawaban dari soal yang lain.
  if (
    originalValues.indexOf(undefined) > -1 ||
    originalLabels.indexOf(undefined) > -1 ||
    values.indexOf(undefined) > -1 ||
    originalValues.length !== values.length
  ) {
    return null;
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={values} strategy={verticalListSortingStrategy}>
        {values.map((value) => (
          <SortableItem
            key={value}
            value={value}
            label={originalLabels[originalValues.indexOf(value)]}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
}

ExamSort = forwardRef(ExamSort);

export default memo(ExamSort);
