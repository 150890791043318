import { useCallback, useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import ExamContext from 'context/exam.context';
import Exam from 'components/Exam';
import DashboardContainer from 'components/Layouts/Dashboard/DashboardContainer';
import { SIDEBAR_EXAM } from 'constant';
import ExamControllerContext from 'context/examController.context';
import { call } from 'utils/caller';

function ExamPage() {
  const history = useHistory();
  const [exams] = useContext(ExamContext);
  const [{ isStart, isReady }, dispatch] = useContext(ExamControllerContext);

  const childRef = useRef();

  // Menangani apabila peserta menjawab/mengubah jawaban.
  const handleAnswerChange = useCallback(
    (index, newAnswer) => {
      call(dispatch.answer, index, newAnswer);
    },
    [dispatch.answer]
  );

  // Menangani apabila peserta ingin menyelesaikan ujian.
  const handleFinish = useCallback(async () => {
    call(dispatch.finish);
  }, [dispatch.finish]);

  // Memindahkan halaman ketika belum ada ujian yang dimulai.
  useEffect(() => {
    if (exams.length === 0 || !isStart) {
      // Memindahkan ke halaman dashboard apabila data exam belum diambil.
      history.replace('/dashboard');
    }
  }, [exams.length, history, isStart]);

  return (
    <DashboardContainer activeSidebarItem={SIDEBAR_EXAM} showProfileOnSidebar>
      {!isReady ? (
        <>Loading...</>
      ) : (
        <Exam
          ref={childRef}
          onAnswerChange={handleAnswerChange}
          onFinish={handleFinish}
        />
      )}
    </DashboardContainer>
  );
}

export default ExamPage;
