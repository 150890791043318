/* eslint-disable react/prop-types */
import { DndContext } from '@dnd-kit/core';
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import Draggable, { DraggableSlot } from './Draggable';
import Droppable, { DroppableChoiceContainer } from './Droppable';

import Typography from 'components/Typography';
import { isImage } from 'utils/exam';
import { safeJoinURL } from 'libs/url';
import useBaseURL from 'hooks/useBaseURL';

function ExamDrag(
  { question: { id, choices: _choices, additional }, answer, onAnswerChange },
  ref // eslint-disable-line no-unused-vars
) {
  const choiceContainerId = `choices-container-${id}`;

  const path = useBaseURL();

  // Items adalah list dari label.
  const items = useMemo(
    () => Object.entries(additional).map(([key, value]) => ({ key, value })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [additional]
  );

  // Choices adalah list dari jawaban yang tersedia.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const choices = useMemo(() => _choices, [_choices]);

  /**
   * Jawaban yang sudah dimasukkan ke dalam box masing-masing pertanyaan. Urutan
   * isi dari array sesuai dengan urutan additional/tcck. Setiap elemen pada
   * array harus diisi dengan null jika belum dijawab, dan harus diisi angka
   * (index) dari pilihan jawaban (choices) jika sudah dijawab.
   * @type {[Array<number|null>, Function]>}
   */
  const [values, setValues] = useState([]);

  // Menangani aksi on drop.
  const handleDragEnd = useCallback(
    ({ active, over }) => {
      // Mencegah error ketika nilai dari over adalah null.
      if (!over) return;

      // Jika jawaban saat ini tidak didrop kembali ke kontainer jawaban.
      const isOnChoiceContainer = values.indexOf(parseInt(active.id, 10)) < 0;
      if (isOnChoiceContainer && over.id === choiceContainerId) return;

      // Jawaban baru.
      const newValue = values.slice();

      // Index dari jawaban saat ini yang terdaftar pada values.
      const currentAnsweredIndex = newValue.indexOf(parseInt(active.id, 10));

      // Jika jawaban ini sudah dijawabkan ke container kiri, maka hilangkan
      // jawaban tersebut dari values.
      if (currentAnsweredIndex > -1) newValue[currentAnsweredIndex] = null;

      // Jika jawaban di drop ke container sebelah kiri (mengubah posisi jawaban
      // setelah menjawab).
      if (over.id !== choiceContainerId) {
        // Posisi pertanyaan yang dituju.
        const targetIndex = parseInt(over.id, 10);

        // Mengubah jawaban dari pertanyaan yang dituju.
        newValue[targetIndex] = parseInt(active.id, 10);
      }

      // String jawaban baru.
      const newAnswer = newValue
        .map((value) =>
          value !== null
            ? (value + 1).toString() // (value + 1) artinya mengubah index menjadi urutan angka
            : ' '
        )
        .join('');

      // Mengubah jawaban menjadi ' ' jika tidak ada yang dijawab.
      onAnswerChange(/(^$)|(^\s+$)/i.test(newAnswer) ? ' ' : newAnswer);
      setValues(newValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onAnswerChange, values]
  );

  // Menangani inisiasi nilai dari values saat komponen pertama kali dimuat atau
  // saat id berubah.
  useEffect(() => {
    if (answer === ' ') {
      // Isi values dengan null yang menandakan bahwa belum ada jawaban.
      setValues(new Array(_choices.length).fill(null));
      return;
    }

    setValues(
      answer.split('').map(
        (value) => (value === ' ' ? null : parseInt(value, 10) - 1) // - 1 artinya yang digunakan dalam memanajemen jawaban adalah indexnya.
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Mereset values jika nilai answer berubah menjadi kosong.
  useEffect(() => {
    if (answer === ' ') {
      setValues(new Array(_choices.length).fill(null));
    }
  }, [_choices.length, answer]);

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <div className="flex justify-between gap-10">
        {/* Container untuk jawaban */}
        <div className="flex flex-col gap-5">
          {values.map((value, index) => {
            const item = items[index];
            const hasAnswer = value !== null;
            const currentAnswer = choices[value];

            return (
              <div
                key={index}
                className="flex justify-start items-center gap-3"
              >
                {/* Nomor jawaban */}
                <Typography className="font-readable">{index + 1}.</Typography>

                {isImage(item?.value) ? (
                  <img
                    alt={item.value}
                    src={safeJoinURL(path.questionImage, item.value)}
                    className="h-32 max-w-80 max-h-32"
                  />
                ) : (
                  <Typography
                    as="div"
                    className="font-readable whitespace-nowrap"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: `${item?.value} = `,
                    }}
                  />
                )}

                {hasAnswer ? (
                  <Draggable id={value.toString()} label={currentAnswer} />
                ) : (
                  <Droppable id={index.toString()} />
                )}
              </div>
            );
          })}
        </div>

        {/* Container untuk pilihan jawaban */}
        <DroppableChoiceContainer id={choiceContainerId}>
          <div className="flex flex-col gap-5">
            {choices.map((choice, index) =>
              // Jika pilihan ini sudah digunakan pada salah satu jawaban.
              values.indexOf(index) > -1 ? (
                <DraggableSlot key={index} />
              ) : (
                // toString digunakan agar bisa di drag.
                <Draggable key={index} id={index.toString()} label={choice} />
              )
            )}
          </div>
        </DroppableChoiceContainer>
      </div>
    </DndContext>
  );
}

ExamDrag = forwardRef(ExamDrag);

export default memo(ExamDrag);
