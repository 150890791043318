/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */

/**
 * @typedef Option
 * @type {object}
 * @property {string} value
 * @property {string} text
 */

import { forwardRef, useCallback, useMemo } from 'react';
import colors from 'tailwindcss/colors';
import { MathJax } from 'better-react-mathjax';

// import Select from 'components/Select';
import { getAlphabetByIndex, getTrueByIndex } from 'utils/exam';
// import { getAlphabetByIndex } from 'utils/exam';
// import * as examHelper from 'utils/exam';
import useBaseURL from 'hooks/useBaseURL';
import clsx from 'libs/clsx';
import Typography from 'components/Typography';
// import { safeJoinURL } from 'libs/url';

function ExamMatch(
  { question: { id, choices, additional }, answer, onAnswerChange },
  ref // eslint-disable-line no-unused-vars
) {
  const path = useBaseURL();

  /**
   * Menampung list sub pertanyaan.
   * @type {Array<string>}
   */
  const subQuestions = useMemo(
    () => Object.entries(additional).map(([, value]) => value),
    [additional]
  );

  /**
   * Menampung list dari jawaban peserta yang sudah dikelola.
   * @type {Array<string>}
   */
  const serializedAnswer = useMemo(() => {
    if (answer === ' ') return subQuestions.map(() => ' ');
    return subQuestions.map((value, index) => answer[index] || ' ');
  }, [answer, subQuestions]);

  /**
   * Menampung list pilihan jawaban yang tersedia.
   * @type {Array<Option>}
   */
  const options = useMemo(
    () =>
      choices.map((value, index) => ({
        value: getTrueByIndex(index),
        text: `${value}`,
      })),
    [choices]
  );

  const handleChange = useCallback(
    (index, value) => {
      const newSerializedAnswer = serializedAnswer.slice();
      newSerializedAnswer[index] = value;
      const newAnswer = newSerializedAnswer.join('');

      // Semua jawaban masih kosong.
      const areAnswersEmpty = /^\s+$/i.test(newAnswer);
      onAnswerChange(areAnswersEmpty ? ' ' : newAnswer);
    },
    [onAnswerChange, serializedAnswer]
  );

  return (
    <>
      {subQuestions.map((subQuestion, index) => {
        const questionArabic = /[\u0600-\u06FF]/.test(options.text);

        const tempChoiceString = subQuestion.replace(
          /@url\//g,
          `${path.questionImage}c/`
        );

        return (
          <div key={index} className="mb-4" id="answer-container">
            <div dir="auto">
              <MathJax>
                <span
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: tempChoiceString }}
                />
              </MathJax>
            </div>

            {options.map((option, optionIndex) => {
              const choiceId = `choice-${id}-${index}-${optionIndex}`;

              const alphabetLabel = getAlphabetByIndex(optionIndex);

              const isSelected = serializedAnswer[index] === option.value;

              return (
                <div
                  key={`option-${index}-${optionIndex}`}
                  className={clsx(
                    'answer-container',
                    'flex items-center border-2 border-gray-100 rounded-lg p-2.5 my-3 shadow cursor-pointer select-none',
                    isSelected && 'border-2 border-green-400'
                  )}
                  onClick={() => handleChange(index, option.value)}
                  onKeyDown={() => {}}
                  role="button"
                  tabIndex={0}
                >
                  <input
                    type="radio"
                    id={choiceId}
                    name={choiceId}
                    value={option.value}
                    onChange={(e) => handleChange(index, e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                    checked={isSelected}
                    className="h-4 w-4 text-gray-700 px-3 py-3 border rounded mr-2"
                  />
                  <label
                    htmlFor={choiceId}
                    className="cursor-pointer ml-1"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="flex items-center gap-2">
                      <Typography className="font-normal">
                        {alphabetLabel}.
                      </Typography>
                      <Typography
                        as="div"
                        variant={questionArabic ? 'heading3' : 'paragraph'}
                        className="font-normal"
                        style={{
                          fontFamily: questionArabic
                            ? 'Traditional Arabic'
                            : 'inherit',
                        }}
                      >
                        <MathJax>
                          <span
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                              __html: option.text,
                            }}
                          />
                        </MathJax>
                      </Typography>
                    </div>
                  </label>
                </div>
              );
            })}
          </div>
        );
      })}
      <style jsx="true">{`
        #answer-container label {
          color: ${colors.gray['800']};
          font-weight: 400;
        }
      `}</style>
      <style jsx="true">{`
        #answer-container select {
          font-family: 'Verdana';
          font-size: '1rem';
        }

        #answer-container select.arabic {
          font-family: 'Traditional Arabic';
          font-size: '2rem';
        }
      `}</style>
      {/* Mengubah warna dari radio button */}
      <style jsx="true">{`
        input[type='radio'] {
          visibility: hidden; /* hide default radio button */
          /* you may need to adjust margin here, too */
        }
        input[type='radio']::before {
          /* create pseudoelement */
          border: 1px solid rgba(16, 185, 129, 1); /* thickness, style, color */
          height: 0.9em; /* height adjusts with font */
          width: 0.9em; /* width adjusts with font */
          border-radius: 50%; /* make it round */
          display: block; /* or flex or inline-block */
          content: ' '; /* won't display without this */
          cursor: pointer; /* appears clickable to mouse users */
          visibility: visible; /* reverse the 'hidden' above */
        }

        input[type='radio']:checked::before {
          /* selected */
          /* add middle dot when selected */
          /* slightly bigger second value makes it smooth */
          /* even more (e.g., 20% 50%) would make it fuzzy */
          background: radial-gradient(
            rgba(16, 185, 129, 1) 50%,
            transparent 0%
          );
        }
      `}</style>
    </>
  );
}

ExamMatch = forwardRef(ExamMatch);

export default ExamMatch;
