/* eslint-disable import/prefer-default-export */

/**
 * Function caller.
 * @template {(...args: any[]) => any} T
 * @param {T} callback
 * @param {Parameters<T>} args
 * @returns {ReturnType<T>}
 */
export const call = (callback, ...args) => callback(...args);
