import { useCallback, useState } from 'react';

/**
 * @typedef UseStacker
 * @property {number} stacks
 * @property {() => boolean} hasStack
 * @property {() => void} add
 * @property {() => void} done
 * @property {() => void} reset
 */

/**
 * Custom hook yang digunakan untuk menyimpan data angka (0 sampai n) untuk
 * menyelesaikan suatu permasalahan.
 * @returns {UseStacker}
 */
export default function useStacker() {
  const [stacks, setStacks] = useState(0);

  /**
   * Check stack.
   */
  const hasStack = useCallback(() => stacks > 0, [stacks]);

  /**
   * Add stack.
   */
  const add = useCallback(() => {
    setStacks((currentStacks) => currentStacks + 1);
  }, []);

  /**
   * Subtract stack by 1.
   */
  const done = useCallback(() => {
    setStacks((currentStacks) => (currentStacks === 0 ? 0 : currentStacks - 1));
  }, []);

  /**
   * Reset stack to zero.
   */
  const reset = useCallback(() => {
    setStacks(0);
  }, []);

  return {
    stacks,
    hasStack,
    add,
    done,
    reset,
  };
}
