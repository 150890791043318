import { useCallback, useContext, useEffect } from 'react';
import propTypes from 'prop-types';

import ExamContext from 'context/exam.context';
import api from 'api';
import useIsAuthenticated from 'hooks/useIsAuthenticated';
import { setExams } from 'actions/exam.action';
import { handleHttpError } from 'utils/httpError';
// import WebCam from 'components/WebCam';
// import { IMAGE_MIME_TYPE } from 'constant';
// import { sanitizeSelfieImage } from 'utils/file';
import AppContext from 'context/app.context';
import HttpRequest from 'utils/httpRequest';
import PhotoContext from 'context/photo.context';
import { setSelfiePhoto } from 'actions/photo.action';
import ExamControllerContext from 'context/examController.context';
import { call } from 'utils/caller';

// const PHOTO_WIDTH = 1280;

function Dashboard({ children }) {
  const isAuthenticated = useIsAuthenticated();
  const [{ timerFoto }] = useContext(AppContext);
  const [, dispatchPhoto] = useContext(PhotoContext);
  const [, dispatchExam] = useContext(ExamContext);
  const [{ examId, isStart, remainingTime, duration }, dispatch] = useContext(
    ExamControllerContext
  );

  // const webcamRef = useRef(undefined);

  // Mendapatkan semua data ujian dari peserta.
  const fetchExams = useCallback(async () => {
    try {
      const response = await new HttpRequest(api.exam.getExamPackets()).call();
      dispatchExam(setExams(response.data.data));
    } catch (err) {
      handleHttpError(err);
    }
  }, [dispatchExam]);

  // Mendapatkan foto selfie.
  const getSelfiePhoto = useCallback(async () => {
    try {
      const response = await new HttpRequest(
        api.exam.getSelfieWithCardPhoto()
      ).call();
      dispatchPhoto(setSelfiePhoto(response.data.data.photo));
    } catch (err) {
      if (err?.response?.status !== 404) handleHttpError(err);

      dispatchPhoto(setSelfiePhoto(null));
    }
  }, [dispatchPhoto]);

  const uploadPhoto = useCallback(async () => {
    // if (!webcamRef.current || isSimulation) return;
    // try {
    //   const webcamWidth = webcamRef.current.video.clientWidth;
    //   const webcamHeight = webcamRef.current.video.clientHeight;
    //   const difference = PHOTO_WIDTH / webcamWidth;
    //   const capturedImage = webcamRef.current.getScreenshot({
    //     width: webcamWidth * difference,
    //     height: webcamHeight * difference,
    //   });
    //   const data = new FormData();
    //   data.append('photo', await sanitizeSelfieImage(capturedImage));
    //   await new HttpRequest(api.exam.postOnExamTimePhoto(_examId))
    //     .setData(data)
    //     .call();
    // } catch (err) {
    //   handleHttpError(err);
    // }
  }, []);

  // Mendapatkan data ujian dan foto selfie saat peserta terautenikasi.
  useEffect(() => {
    if (isAuthenticated) fetchExams();
  }, [fetchExams, isAuthenticated]);

  // Mendapatkan foto selfie jika peserta baru login.
  useEffect(() => {
    if (isAuthenticated) getSelfiePhoto();
  }, [getSelfiePhoto, isAuthenticated]);

  // @todo mengubah logic ini
  useEffect(() => {
    if (!isStart) return;
    if (
      duration !== 0 &&
      (remainingTime === duration || remainingTime % timerFoto === 0)
    ) {
      uploadPhoto(examId);
    }
  }, [duration, examId, isStart, remainingTime, timerFoto, uploadPhoto]);

  // Mereset ujian saat tombol logout diklik.
  useEffect(() => () => call(dispatch.reset), [dispatch.reset]);

  return (
    <>
      {children}

      {/* <WebCam
        ref={webcamRef}
        audio={false}
        controls={false}
        screenshotFormat={IMAGE_MIME_TYPE}
        className="absolute -top-96 w-44"
      /> */}

      <style jsx="true">{`
        body {
          background-color: #f7f9fd;
        }
      `}</style>
    </>
  );
}

Dashboard.propTypes = {
  children: propTypes.node.isRequired,
};

export default Dashboard;
