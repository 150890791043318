import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import propTypes from 'prop-types';
import { MathJax } from 'better-react-mathjax';

// import * as examHelper from 'utils/exam';
import useBaseURL from 'hooks/useBaseURL';
// import { safeJoinURL } from 'libs/url';

function SortableItem({ value, label }) {
  const path = useBaseURL();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: value });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const styleArabic = {
    transform: CSS.Transform.toString(transform),
    transition,
    fontFamily: 'Traditional Arabic',
    ontSize: '2rem',
  };

  const questionArabic = /[\u0600-\u06FF]/.test(label);

  const tempChoiceString = label.replace(/@url\//g, `${path.questionImage}c/`);

  return (
    <div
      ref={setNodeRef}
      style={questionArabic ? styleArabic : style}
      className="my-4 px-5 py-3 bg-gray-100 shadow-md select-none font-medium text-base cursor-move"
      {...attributes}
      {...listeners}
    >
      <MathJax>
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: tempChoiceString }} />
      </MathJax>
    </div>
  );
}

SortableItem.propTypes = {
  value: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
};

export default SortableItem;
