import {
  EXAM_PACKET_FINISHED,
  EXAM_PACKET_NOT_STARTED_YET,
  EXAM_PACKET_STARTED,
  EXAM_READY,
  EXAM_STATUS_STAGE1,
  EXAM_STATUS_STAGE2,
  EXAM_STATUS_STAGE3,
  EXAM_STATUS_DONE,
  EXAM_STATUS_NOT_ACTIVE,
  EXAM_STATUS_ACTIVE,
  EXAM_STATUS_WRONG_DATE,
} from 'constant';

export function getAlphabetByIndex(index) {
  switch (index) {
    case 0:
      return 'A';
    case 1:
      return 'B';
    case 2:
      return 'C';
    case 3:
      return 'D';
    case 4:
      return 'E';
    case 5:
      return 'F';
    default:
      return null;
  }
}

export function getTrueByIndex(index) {
  switch (index) {
    case 0:
      return 'B';
    case 1:
      return 'S';
    case 2:
      return 'C';
    case 3:
      return 'D';
    case 4:
      return 'E';
    default:
      return null;
  }
}

export function getIndexByAlphabet(alphabet) {
  switch (alphabet) {
    case 'A':
      return 0;
    case 'B':
      return 1;
    case 'C':
      return 2;
    case 'D':
      return 3;
    case 'E':
      return 4;
    case 'F':
      return 5;
    default:
      return -1;
  }
}

export function getPercentage(exam) {
  let percentage = 0;

  const isExamStarted = EXAM_PACKET_STARTED.includes(exam.paket_ujian.sta);
  const isExamFinished = exam.paket_ujian.sta === EXAM_PACKET_FINISHED;

  if (isExamStarted || isExamFinished) {
    let answers;
    if (exam.paket_ujian.sta === 2) {
      answers = exam.paket_ujian.jwb2;
    } else if (exam.paket_ujian.sta === 3) {
      answers = exam.paket_ujian.jwb3;
    } else if (exam.paket_ujian.sta === 4) {
      if (typeof exam.paket_ujian.jwb3 === 'object') {
        answers = exam.paket_ujian.jwb3;
      } else if (typeof exam.paket_ujian.jwb2 === 'object') {
        answers = exam.paket_ujian.jwb2;
      } else {
        answers = exam.paket_ujian.jwb2;
      }
    } else {
      answers = exam.paket_ujian.jwb;
    }

    if (Array.isArray(answers)) {
      percentage = Math.floor(
        (answers.filter((answer) => answer !== ' ').length / answers.length) *
          100
      );
    }
  }

  return percentage;
}

export function getCurrentDate() {
  const currentDate = new Date(
    new Date().toLocaleString('en-US', { timeZone: 'Asia/Makassar' })
  );
  currentDate.setHours(0, 0, 0, 0);

  return currentDate;
}

export function getNearestStage(exam) {
  let stage;
  const status = exam.paket_ujian.sta;

  if (status === EXAM_PACKET_NOT_STARTED_YET) {
    // eslint-disable-next-line prefer-destructuring
    stage = EXAM_PACKET_STARTED[0];
  } else if (status === EXAM_PACKET_FINISHED) {
    stage = EXAM_PACKET_STARTED[EXAM_PACKET_STARTED.length - 1];
  } else {
    stage = status;
  }

  return stage;
}

export function getExamDate(exam) {
  const examDate = new Date(
    new Date(exam.ujian.tgl).toLocaleString('en-US', {
      timeZone: 'Asia/Makassar',
    })
  );
  examDate.setHours(0, 0, 0, 0);

  return examDate;
}

export function getStatus(exam) {
  let status = EXAM_STATUS_NOT_ACTIVE;

  const isSameDate = getCurrentDate().getTime() === getExamDate(exam).getTime();
  const isCurrentDateGreaterThanExamDate =
    getCurrentDate().getTime() > getExamDate(exam).getTime();
  const isExamReady = exam.ujian.sta === EXAM_READY;
  const isExamPacketNotStartedYet =
    exam.paket_ujian.sta === EXAM_PACKET_NOT_STARTED_YET;
  const isExamPacketStarted = EXAM_PACKET_STARTED.includes(
    exam.paket_ujian.sta
  );

  if (
    exam.paket_ujian.sta === EXAM_PACKET_FINISHED ||
    (isExamPacketStarted && !isExamReady)
  ) {
    status = EXAM_STATUS_DONE;
  } else if (
    (isSameDate || isCurrentDateGreaterThanExamDate) &&
    isExamReady &&
    isExamPacketStarted
  ) {
    if (exam.paket_ujian.sta === 1) {
      status = EXAM_STATUS_STAGE1;
    } else if (exam.paket_ujian.sta === 2) {
      status = EXAM_STATUS_STAGE2;
    } else if (exam.paket_ujian.sta === 3) {
      status = EXAM_STATUS_STAGE3;
    }
  } else if (isSameDate && isExamReady && isExamPacketNotStartedYet) {
    status = EXAM_STATUS_ACTIVE;
  } else if (
    isCurrentDateGreaterThanExamDate &&
    isExamReady &&
    isExamPacketNotStartedYet
  ) {
    status = EXAM_STATUS_ACTIVE;
  } else if (!isSameDate && exam.paket_ujian.sta !== EXAM_PACKET_FINISHED) {
    status = EXAM_STATUS_WRONG_DATE;
  }

  return status;
}

export function getRemainingTime(exam) {
  const isExamPacketNotStartedYet =
    exam.paket_ujian.sta === EXAM_PACKET_NOT_STARTED_YET;

  const isSameDate = getCurrentDate().getTime() === getExamDate(exam).getTime();
  const isExamReady = exam.ujian.sta === EXAM_READY;

  if (isSameDate && isExamReady && isExamPacketNotStartedYet)
    return exam.ujian.drs;

  if (
    exam.paket_ujian.sta === 2 &&
    typeof exam.paket_ujian.ssw2 !== 'undefined'
  ) {
    return exam.paket_ujian.ssw2;
  }
  if (
    (exam.paket_ujian.sta === 3 || exam.paket_ujian.sta === 4) &&
    typeof exam.paket_ujian.ssw3 !== 'undefined'
  ) {
    return exam.paket_ujian.ssw3;
  }

  return exam.paket_ujian.ssw;
}

export function isImage(string) {
  return String(string).match(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i);
}

export const shuffleChoices = (choices) => {
  const shuffledChoices = [...choices];
  const shuffledIndexes = Array.from({ length: choices.length }, (v, i) => i);

  for (let i = choices.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    const tempChoice = shuffledChoices[i];
    const tempIndex = shuffledIndexes[i];

    shuffledChoices[i] = shuffledChoices[j];
    shuffledChoices[j] = tempChoice;

    shuffledIndexes[i] = shuffledIndexes[j];
    shuffledIndexes[j] = tempIndex;
  }

  return [shuffledChoices, shuffledIndexes];
};
