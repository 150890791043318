/* eslint-disable no-restricted-syntax */
import { useCallback, useContext, useEffect, useState } from 'react';
import { NOTIFICATION_RECEIVED } from 'electron-push-receiver/src/constants';

import QuestionAnsweredModal from './QuestionAnsweredModal';
import WarningModal from './WarningModal';

import api from 'api';
import ExamContext from 'context/exam.context';
import UserContext from 'context/user.context';
import messaging from 'utils/messaging';
import { setUser } from 'actions/user.action';
import useIsAuthenticated from 'hooks/useIsAuthenticated';
import { setExams } from 'actions/exam.action';
import { isElectron } from 'utils/electron';
import HttpRequest from 'utils/httpRequest';
import {
  subscribeHelpAnsweredEvent,
  subscribeWarningEvent,
  unsubscribeHelpAnsweredEvent,
  unsubscribeWarningEvent,
} from 'utils/events';

function EventHandler() {
  const isAuthenticated = useIsAuthenticated();

  const [, dispatchUser] = useContext(UserContext);
  const [exams, dispatchExam] = useContext(ExamContext);

  const [isQuestionAnswered, setIsQuestionAnswered] = useState(false);
  const [questionAnsweredTitle, setQuestionAnsweredTitle] = useState(null);
  const [questionAnsweredText, setQuestionAnsweredText] = useState(null);

  const [warnings, setWarnings] = useState([]);

  // Menangani aksi menutup modal pertanyaan telah dijawab.
  const handleQuestionAnsweredModalClose = useCallback(() => {
    setIsQuestionAnswered(false);
    setQuestionAnsweredTitle(null);
    setQuestionAnsweredText(null);
  }, []);

  // Menangani aksi menutup modal teguran dari pengawas.
  const handleWarningModalClose = useCallback(
    (examId) => {
      for (const warning of warnings) {
        if (warning.examId === examId) {
          new HttpRequest(api.exam.readWarning(examId)).call();

          for (let i = 0; i < exams.length; i += 1) {
            if (exams[i].paket_ujian.id === examId) {
              // Angka genap berarti teguran telah dibaca.
              exams[i].paket_ujian.isTegur = warning.isTegur + 1;
            }
          }
          dispatchExam(setExams(exams));

          setWarnings(warnings.filter((w) => w.examId !== examId));
          break;
        }
      }
    },
    [dispatchExam, exams, warnings]
  );

  // Otomatis memunculkan popup teguran saat ada teguran jika ada.
  useEffect(() => {
    if (exams.length > 0) {
      const newWarnings = [];

      for (const exam of exams) {
        // Angka ganjil berarti memiliki teguran.
        if (exam.paket_ujian.isTegur % 2 === 1) {
          newWarnings.push({
            examId: exam.paket_ujian.idUj,
            title: 'Teguran dari Pengawas',
            text: exam.paket_ujian.textTegur,
            status: exam.paket_ujian.isTegur,
          });
        }
      }

      setWarnings((currentValue) => [...currentValue, ...newWarnings]);
    }
  }, [exams]);

  // Menangani pengelolahan data notifikasi.
  const handleNotificationReceived = useCallback(
    (notification, data) => {
      switch (data.type) {
        case 'QUESTION_ANSWERED':
          if (isAuthenticated) {
            new HttpRequest(api.profile.detail())
              .call()
              .then((res) => res.data)
              .then((_data) => dispatchUser(setUser(_data.data)));
          }

          setIsQuestionAnswered(true);
          setQuestionAnsweredTitle(notification.title);
          setQuestionAnsweredText(data.textJawab);
          break;
        case 'WARNING':
          if (!isAuthenticated) return;

          setWarnings((currentValue) => [
            ...currentValue,
            {
              title: 'Teguran dari Pengawas',
              text: notification.body,
              examId: parseInt(data.exam_id, 10),
              status: parseInt(data.isTegur, 10),
            },
          ]);
          break;
        default:
      }
    },
    [dispatchUser, isAuthenticated]
  );

  // Menangani penerimaan notifikasi saat menggunakan website.
  useEffect(() => {
    if (isElectron()) return;

    messaging?.onMessage(({ notification, data }) =>
      handleNotificationReceived(notification, data)
    );
  }, [dispatchUser, handleNotificationReceived]);

  // Menangani penerimaan notifikasi saat menggunakan electron.
  useEffect(() => {
    if (!isElectron()) return;

    const handle = (_, { notification, data }) =>
      handleNotificationReceived(notification, data);
    window.ipcRenderer.on(NOTIFICATION_RECEIVED, handle);

    // eslint-disable-next-line consistent-return
    return () =>
      window.ipcRenderer.removeListener(NOTIFICATION_RECEIVED, handle);
  }, [handleNotificationReceived]);

  // Melisten event teguran yang didapatkan secara manual melalui interval.
  useEffect(() => {
    const handle = (e) => {
      setWarnings(
        e.value.map((warning) => ({
          title: 'Teguran dari Pengawas',
          text: warning.textTegur,
          examId: warning.idUj,
          status: warning.isTegur,
        }))
      );
    };

    subscribeWarningEvent(handle);
    return () => unsubscribeWarningEvent(handle);
  }, []);

  // Melisten event pertanyaan telah dijawab yang didapatkan secara manual
  // melalui interval.
  useEffect(() => {
    const handle = (e) => {
      if (isAuthenticated) {
        new HttpRequest(api.profile.detail())
          .call()
          .then((res) => res.data)
          .then((_data) => dispatchUser(setUser(_data.data)));
      }

      setIsQuestionAnswered(true);
      setQuestionAnsweredTitle('Pertanyaan Telah Dijawab');
      setQuestionAnsweredText(e.value.textJawab);
    };

    subscribeHelpAnsweredEvent(handle);
    return () => unsubscribeHelpAnsweredEvent(handle);
  }, [dispatchUser, isAuthenticated]);

  return (
    <>
      <QuestionAnsweredModal
        isOpen={isQuestionAnswered}
        isAuthenticated={isAuthenticated}
        title={questionAnsweredTitle}
        text={questionAnsweredText}
        onClose={handleQuestionAnsweredModalClose}
      />

      {warnings.map((warning, key) => (
        <WarningModal
          key={key}
          isOpen
          title={warning.title}
          text={warning.text}
          onClose={() => handleWarningModalClose(warning.examId)}
        />
      ))}
    </>
  );
}

export default EventHandler;
