function CloseAppButton() {
  const handleCloseButtonClick = () => {
    window?.ipcRenderer?.invoke('runForceQuit');
  };

  return (
    <button
      type="button"
      className="fixed top-0 right-0 bg-danger text-white px-4 py-2 text-3xl z-50"
      onClick={handleCloseButtonClick}
    >
      &times;
    </button>
  );
}

export default CloseAppButton;
