import propTypes from 'prop-types';
import { forwardRef } from 'react';

import clsx from 'libs/clsx';

const variantClassNames = {
  heading1: 'font-medium 2xl:text-5xl text-4xl',
  heading2: 'font-medium 2xl:text-4xl text-3xl',
  heading3: 'font-medium 2xl:text-3xl text-2xl',
  subtitle1: 'font-light 2xl:text-2xl text-xl',
  subtitle2: 'font-light 2xl:text-xl text-lg',
  subtitle3: 'font-light 2xl:text-lg text-base',
  paragraph: 'font-light 2xl:text-base text-sm',
  small: 'font-light xl:text-sm text-xs',
  'extra-small': 'font-light xl:text-xs text-xs',
};

const colors = {
  muted: 'text-gray-500',
  dark: 'text-gray-700',
  light: 'text-white',
  initial: 'text-current',
};

const gutterBottomSizes = {
  heading1: 'xl:mb-6 mb-5',
  heading2: 'xl:mb-5 mb-4',
  heading3: 'xl:mb-4 mb-3',
  subtitle1: 'xl:mb-3 mb-2',
  subtitle2: 'xl:mb-3 mb-2',
  subtitle3: 'xl:mb-3 mb-2',
  paragraph: 'xl:mb-3 mb-2',
  small: 'xl:mb-3 mb-2',
  'extra-small': 'xl:mb-3 mb-2',
};

const variants = {
  heading1: 'h1',
  heading2: 'h2',
  heading3: 'h3',
  subtitle1: 'h4',
  subtitle2: 'h5',
  subtitle3: 'h6',
  paragraph: 'p',
  small: 'p',
  'extra-small': 'p',
};

function Typography(
  { as: CustomTag, color, variant, gutterBottom, className, ...props },
  ref
) {
  const Tag = CustomTag !== null ? CustomTag : variants[variant];

  return (
    <Tag
      ref={ref}
      className={clsx(
        variantClassNames[variant],
        colors[color],
        gutterBottom && gutterBottomSizes[variant],
        className
      )}
      {...props}
    />
  );
}

Typography = forwardRef(Typography);

Typography.defaultProps = {
  as: null,
  color: 'initial',
  className: '',
  variant: 'paragraph',
  gutterBottom: false,
};

Typography.propTypes = {
  as: propTypes.string,
  color: propTypes.oneOf(Object.entries(colors).map(([key]) => key)),
  className: propTypes.string,
  variant: propTypes.oneOf(Object.entries(variants).map(([key]) => key)),
  gutterBottom: propTypes.bool,
};

export default Typography;
