import { useContext } from 'react';

import logo from 'assets/images/logo.png';
import AppContext from 'context/app.context';
import Typography from 'components/Typography';

function Logo() {
  const [app] = useContext(AppContext);

  return (
    <div className="block flex items-center gap-5">
      <img src={logo} alt="Logo" className="lg:w-16 md:w-14 sm:w-12 w-10" />
      <Typography variant="subtitle2" className="font-medium">
        {app.title_ujian}
      </Typography>
    </div>
  );
}

export default Logo;
