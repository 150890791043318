import {
  SET_CAMERA_PERMISSION,
  SET_MICROPHONE_PERMISSION,
  SET_NOTIFICATION_PERMISSION,
} from 'constant';

export const initialPermissionState = {
  cameraAllowed: false,
  microphoneAllowed: false,
  notificationAllowed: false,
};

export default function permissionReducer(
  state = initialPermissionState,
  action
) {
  switch (action.type) {
    case SET_CAMERA_PERMISSION: {
      return { ...state, cameraAllowed: action.payload };
    }
    case SET_MICROPHONE_PERMISSION: {
      return { ...state, microphoneAllowed: action.payload };
    }
    case SET_NOTIFICATION_PERMISSION: {
      return { ...state, notificationAllowed: action.payload };
    }
    default: {
      console.error(`Unhandled action type: ${action.type}`);
      return state;
    }
  }
}
