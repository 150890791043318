import propTypes from 'prop-types';
import { createContext, useReducer } from 'react';

import appReducer, { initialAppState } from 'reducers/app.reducer';

const AppContext = createContext();

export function AppContextProvider({ children }) {
  return (
    <AppContext.Provider value={useReducer(appReducer, initialAppState)}>
      {children}
    </AppContext.Provider>
  );
}

AppContextProvider.propTypes = {
  children: propTypes.node.isRequired,
};

export default AppContext;
