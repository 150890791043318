import { SET_USER, UNSET_USER } from 'constant';

// eslint-disable-next-line import/no-mutable-exports
export let initialUserState = {};

export function setDefaultUserState(state) {
  initialUserState = state;
}

export default function userReducer(state = initialUserState, action) {
  switch (action.type) {
    case SET_USER: {
      return action.payload;
    }
    case UNSET_USER: {
      return {};
    }
    default: {
      console.error(`Unhandled action type: ${action.type}`);
      return state;
    }
  }
}
