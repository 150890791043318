import Button from 'components/Button';
import Typography from 'components/Typography';
import { forceQuit } from 'utils/electron';

function Fallback() {
  return (
    <div className="flex justify-center content-center h-screen">
      <div className="flex flex-wrap content-center">
        <div className="w-80">
          <Typography variant="heading3" className="text-center">
            Ada kesalahan yang telah terjadi.
          </Typography>

          <div className="grid grid-cols-2 gap-3 mt-8">
            <Button
              color="primary"
              type="button"
              onClick={() => window.location.reload()}
              block
            >
              Muat Ulang
            </Button>

            <Button
              color="danger"
              type="button"
              onClick={() => forceQuit()}
              block
            >
              Keluar
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Fallback;
