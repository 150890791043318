import React from 'react';
import propTypes from 'prop-types';

import { AppContextProvider } from './app.context';
import { ExamContextProvider } from './exam.context';
import { PermissionContextProvider } from './permission.context';
import { PhotoContextProvider } from './photo.context';
import { UserContextProvider } from './user.context';
import { ExamControllerContextProvider } from './examController.context';

const registeredContexts = [
  AppContextProvider,
  PermissionContextProvider,
  UserContextProvider,
  ExamContextProvider,
  ExamControllerContextProvider,
  PhotoContextProvider,
  // Register new context here.
];

/**
 * Component yang digunakan untuk menangani nested context agar lebih clean.
 *
 * @param {Object}          props
 * @param {React.ReactNode} props.context   elemen dari context
 * @param {Array}           props.contexts  list dari context
 * @param {React.ReactNode} props.children
 */
function Container({ context: Context, contexts, children }) {
  // Variabel yang menampung array baru. Di mana index 0 akan dihapus.
  const shiftedContexts = React.useMemo(() => {
    const newContexts = [...contexts];
    newContexts.shift();
    return newContexts;
  }, [contexts]);

  // Jika sudah tidak ada lagi context pada array
  if (contexts.length === 0) {
    return <Context>{children}</Context>;
  }

  // Jika pada elemen pertama
  if (!Context) {
    return (
      <Container context={contexts[0]} contexts={shiftedContexts}>
        {children}
      </Container>
    );
  }

  return (
    <Context>
      <Container context={contexts[0]} contexts={shiftedContexts}>
        {children}
      </Container>
    </Context>
  );
}

Container.defaultProps = {
  context: null,
  contexts: [],
};

/**
 * Component yang digunakan untuk menampung semua Context agar lebih clean.
 *
 * @param {Object}          props
 * @param {React.ReactNode} props.children
 */
const ContextContainer = ({ children }) => {
  const contexts = React.useMemo(() => registeredContexts, []);

  return <Container contexts={contexts}>{children}</Container>;
};

Container.propTypes = {
  context: propTypes.func,
  contexts: propTypes.arrayOf(propTypes.func),
  children: propTypes.node.isRequired,
};

ContextContainer.propTypes = {
  children: propTypes.node.isRequired,
};

export default ContextContainer;
