import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

// import Button from 'components/Button';
import Card from 'components/Card';
// import { ReactComponent as BookOpenIcon } from 'assets/icons/book-open.svg';
// import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
// import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import AppContext from 'context/app.context';
import Typography from 'components/Typography';
import Button from 'components/Button';

function ExamInformation() {
  const history = useHistory();
  const [app] = useContext(AppContext);

  const handleSimulationClick = () => {
    history.push('/dashboard/simulasi');
  };

  return (
    <Card>
      <div>
        <Typography variant="subtitle1" className="font-normal" gutterBottom>
          Deskripsi Ujian
        </Typography>

        <Typography
          as="div"
          className="font-normal"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: app.des_ujian }}
        />

        <Button
          type="button"
          color="info"
          className="mt-10"
          onClick={handleSimulationClick}
        >
          Simulasi
        </Button>
      </div>
    </Card>
  );
}

export default ExamInformation;
