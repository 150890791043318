import Swal from 'sweetalert2';

function showSuccessWithTimer(title = null, text = null, timer = 5000) {
  Swal.fire({
    title,
    text,
    timer,
    icon: 'success',
    confirmButtonColor: '#00a65a',
  });
}

function showInfoWithTimer(title = null, text = null, timer = 5000) {
  Swal.fire({
    title,
    text,
    timer,
    icon: 'info',
    confirmButtonColor: '#00a65a',
  });
}

function showErrorWithTimer(title = null, text = null, timer = 5000) {
  Swal.fire({
    title,
    text,
    timer,
    icon: 'error',
    confirmButtonColor: '#00a65a',
  });
}

function showError(title, text, options = {}) {
  return Swal.fire({
    title,
    text,
    icon: 'error',
    confirmButtonColor: '#00a65a',
    ...options,
  });
}

const popup = {
  showError,

  showSuccessWithTimer,
  showInfoWithTimer,
  showErrorWithTimer,
};

export default popup;
