import { useContext, useMemo } from 'react';

import UserContext from 'context/user.context';

export default function useIsAuthenticated() {
  const [user] = useContext(UserContext);

  const isAuthenticated = useMemo(
    () => typeof user.id !== 'undefined',
    [user.id]
  );

  return isAuthenticated;
}
