/* eslint-disable react/prop-types */
import { Formik } from 'formik';
// import MaskedInput from 'react-text-mask';

import { ReactComponent as PersonIcon } from 'assets/icons/person.svg';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import Button from 'components/Button';
import Input from 'components/Input';

function LoginForm({ onSubmit, disabled: formDisabled }) {
  return (
    <Formik initialValues={{ username: '', password: '' }} onSubmit={onSubmit}>
      {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <Input
              type="text"
              id="username"
              name="username"
              label="Username:"
              placeholder="Nomor peserta"
              icon={<PersonIcon className="w-6 text-gray-500" />}
              error={errors.username}
              value={values.username}
              onChange={handleChange}
              disabled={formDisabled || isSubmitting}
              autoFocus
            />
          </div>

          <div className="mb-10">
            <Input
              type="text"
              id="password"
              name="password"
              placeholder="Kata sandi"
              label="Password:"
              icon={<LockIcon className="w-6 text-gray-500" />}
              error={errors.password}
              value={values.password}
              onChange={handleChange}
              disabled={formDisabled || isSubmitting}
            />
          </div>

          <Button
            type="submit"
            color="primary"
            loading={isSubmitting}
            disabled={formDisabled || isSubmitting}
            block
          >
            Login
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default LoginForm;
