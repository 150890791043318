/**
 * @typedef IQuestionModel
 * @property {number} id ID pertanyaan
 * @property {string} type Jenis soal
 * @property {string|null} question Text pertanyaan
 * @property {string|null} additional Tcck
 * @property {string|null} additionalQuestion Text pertanyaan tambahan
 * @property {string|null} image Nama gambar
 * @property {string} subjectMatter Materi
 * @property {string[]|null} choices Pilihan jawaban yang tersedia
 * @property {() => IQuestionModel} setClassForHighlightableImage
 */

/**
 * Question model
 * @param {object} question
 * @returns {IQuestionModel}
 */
export default function QuestionModel(question, index) {
  this.index = index;

  this.id = question.id;
  this.question = question.tks;
  this.additionalQuestion = question.ttb || question.stimulus_text || '';
  this.instruction = question.ptjk;
  this.image = question.gbr;
  this.type = question.jns;
  this.subjectMatter = question.mtr;
  this.additional = question.tcck;

  this.stimulus_id = question.stimulus_id;
  this.stimulus_text = question.stimulus_text;

  this.choices = Array.from(Array(6).keys())
    .map((v, i) => question[`pl${i + 1}`])
    .filter((v) => v !== '')
    .filter((v) => v !== null);

  if (question.jns === 'mlt') {
    this.maxChecked = question.max_checked || 2;
  }

  return this;
}

/**
 * Add class on image for highlightable
 * @returns {IQuestionModel}
 */
QuestionModel.prototype.setClassForHighlightableImage =
  function setClassForHighlightableImage() {
    this.additionalQuestion = this.additionalQuestion
      .replaceAll('<img', '<img class="image-highlightable"')
      .replaceAll('<IMG', '<IMG class="image-highlightable"');

    this.question = this.question
      .replaceAll('<img', '<img class="image-highlightable"')
      .replaceAll('<IMG', '<IMG class="image-highlightable"');

    this.stimulus_text = (this.stimulus_text || '')
      .replaceAll('<img', '<img class="image-highlightable"')
      .replaceAll('<IMG', '<IMG class="image-highlightable"');

    return this;
  };
