import propTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

import useIsAuthenticated from 'hooks/useIsAuthenticated';

export function GuestRoute({ component: Component, ...rest }) {
  const isAuthenticated = useIsAuthenticated();

  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
}

export function ProtectedRoute({ component: Component, ...rest }) {
  const isAuthenticated = useIsAuthenticated();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
}

GuestRoute.propTypes = {
  component: propTypes.func.isRequired,
};

ProtectedRoute.propTypes = {
  component: propTypes.func.isRequired,
};
