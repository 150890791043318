import propTypes from 'prop-types';
import { createContext, useReducer } from 'react';

import examReducer, { initialExamState } from 'reducers/exam.reducer';

const ExamContext = createContext();

export function ExamContextProvider({ children }) {
  return (
    <ExamContext.Provider value={useReducer(examReducer, initialExamState)}>
      {children}
    </ExamContext.Provider>
  );
}

ExamContextProvider.propTypes = {
  children: propTypes.node.isRequired,
};

export default ExamContext;
