import { useContext, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { closeModalImage, openModalImage } from 'actions/app.action';
import AppContext from 'context/app.context';
import Button from 'components/Button';

const ModalImage = () => {
  const [{ isModalImageOpen, modalImageUrl }, dispatchApp] =
    useContext(AppContext);

  const handleClose = () => dispatchApp(closeModalImage());

  // handle document image.image-highlightable click
  useEffect(() => {
    const handleImageHighlightableClick = (e) => {
      const isImage = e.target.tagName === 'IMG';
      const containsHighlightableClass = e.target.classList.contains(
        'image-highlightable'
      );

      if (isImage && containsHighlightableClass) {
        const url = e.target.src;
        dispatchApp(openModalImage(url));
      }
    };

    document.addEventListener('click', handleImageHighlightableClick);

    return () => {
      document.removeEventListener('click', handleImageHighlightableClick);
    };
  }, [dispatchApp]);

  return (
    <Dialog
      open={isModalImageOpen}
      fullWidth
      maxWidth="lg"
      onClose={handleClose}
    >
      <DialogTitle>Detail Gambar</DialogTitle>
      <DialogContent>
        <img className="w-full" src={`${modalImageUrl}`} alt={modalImageUrl} />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" type="button" size="sm" onClick={handleClose}>
          Tutup
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalImage;
