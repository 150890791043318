import propTypes from 'prop-types';
import { forwardRef } from 'react';

import clsx from 'libs/clsx';

const Card = forwardRef(({ className, children, compact, ...props }, ref) => (
  <div
    ref={ref}
    className={clsx(
      'rounded-xl bg-white',
      compact ? 'px-4 py-4' : 'px-6 py-8',
      className
    )}
    {...props}
  >
    {children}
  </div>
));

Card.defaultProps = {
  className: '',
  compact: false,
};

Card.propTypes = {
  className: propTypes.string,
  children: propTypes.node.isRequired,
  compact: propTypes.bool,
};

export default Card;
