import { useContext, useMemo, useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';

import ProfileCard from '../../ProfileCard';

import { ReactComponent as BookOpenIcon } from 'assets/icons/book-open.svg';
// import { ReactComponent as QuestionMarkCircleIcon } from 'assets/icons/question-mark-circle.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/log-out.svg';
import Logo from 'components/Logo';
import { SIDEBAR_EXAM, SIDEBAR_HELP } from 'constant';
import clsx from 'libs/clsx';
// import PhotoWithCardInformation from 'components/Helpers/PhotoWithCard/PhotoWithCardInformation';
import Typography from 'components/Typography';
import ExamControllerContext from 'context/examController.context';
import AppContext from 'context/app.context';
import { toggleSidebar } from 'actions/app.action';

function Sidebar({ activeItem, showProfileCard }) {
  // const isInExam = useRouteMatch('/dashboard/exam/:id');
  const [{ examId, isStart }] = useContext(ExamControllerContext);
  const [{ isSidebarOpen }, dispatchApp] = useContext(AppContext);

  const [isSidebarLinksAbsolute, setIsSidebarLinkAbsolute] = useState(false);
  // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  const sidebarLinks = useMemo(
    () => [
      {
        id: SIDEBAR_EXAM,
        to: isStart ? `/dashboard/exam/${examId}` : '/dashboard',
        icon: BookOpenIcon,
        text: 'Ujian',
      },
      // {
      //   id: SIDEBAR_HELP,
      //   to: '/dashboard/bantuan',
      //   icon: QuestionMarkCircleIcon,
      //   text: 'Bantuan',
      // },
    ],
    [examId, isStart]
  );

  // Adjust styling for sidebar.
  const adjustStyle = useCallback(() => {
    const parentHeight = window.screen.height;
    const sidebarChildHeight = document
      .getElementById('sidebar-child')
      .getBoundingClientRect().height;
    const sidebarLinksHeight = document
      .getElementById('sidebar-links')
      .getBoundingClientRect().height;

    if (sidebarChildHeight + sidebarLinksHeight > parentHeight) {
      setIsSidebarLinkAbsolute(false);
    } else {
      setIsSidebarLinkAbsolute(true);
    }
  }, []);

  const handleToggleSidebar = () => {
    dispatchApp(toggleSidebar());
  };

  // Listen window resize event.
  useEffect(() => {
    const handle = () => adjustStyle();
    window.addEventListener('resize', handle);

    return () => window.removeEventListener('resize', handle);
  }, [adjustStyle]);

  // Adjust style on mount.
  useEffect(() => {
    adjustStyle();
  }, [adjustStyle]);

  return (
    <div
      className={clsx(
        'fixed top-0 bottom-0 bg-white 2xl:w-80 xl:w-72 w-64 shadow-lg overflow-y-auto transition-all duration-300 ease-in-out z-10'
      )}
      style={{
        left: isSidebarOpen ? 0 : '-20rem', // size 80 in tailwind
      }}
    >
      <button
        type="button"
        className={clsx(
          'fixed transition-all duration-300 ease-in-out z-10 bg-white shadow-lg p-2',
          isSidebarOpen ? '2xl:left-80 xl:left-72 left-64' : 'left-0'
        )}
        onClick={handleToggleSidebar}
      >
        <svg
          className={clsx(
            'w-6 h-6 transition-all duration-300 ease-in-out',
            isSidebarOpen ? 'transform rotate-180' : ''
          )}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </button>

      <div id="sidebar-child" className="pt-12">
        {/* Logo */}
        <div className="px-8">
          <Logo />
        </div>

        {showProfileCard && <ProfileCard />}

        {/* Informasi foto dengan kartu */}
        {/* {isInExam && <PhotoWithCardInformation />} */}
      </div>

      {/* Sidebar links */}
      <div
        id="sidebar-links"
        className={clsx(
          isSidebarLinksAbsolute && 'absolute inset-x-0 bottom-0',
          showProfileCard ? 'mt-2' : 'mt-10'
        )}
      >
        {sidebarLinks.map(({ id, to, icon: Icon, text }) => (
          <Link
            key={id}
            to={to}
            className={clsx(
              'block px-8 py-4 font-medium',
              id === activeItem
                ? 'bg-green-100 text-green-600 border-l-8 border-green-600 hover:bg-green-200 hover:text-green-700'
                : 'bg-white text-gray-400 border-l-8 border-transparent hover:bg-gray-100 hover:text-gray-600 hover:border-gray-600'
            )}
          >
            <div className="flex items-center gap-4 text-base">
              <Icon className="w-8" />
              <Typography as="span" className="font-medium">
                {text}
              </Typography>
            </div>
          </Link>
        ))}

        {/* Logout button */}
        <Link
          to="/logout"
          className="flex items-center gap-4 text-base text-red-500 border-l-8 border-transparent px-8 py-5 font-medium hover:bg-red-200 hover:text-red-600 hover:border-red-500"
        >
          <LogoutIcon className="w-8" />
          <Typography as="span" className="font-medium">
            Keluar
          </Typography>
        </Link>
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  activeItem: propTypes.oneOf([SIDEBAR_EXAM, SIDEBAR_HELP]).isRequired,
  showProfileCard: propTypes.bool.isRequired,
};

export default Sidebar;
