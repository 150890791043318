import { useRef, useState, useCallback, useContext } from 'react';
import propTypes from 'prop-types';

import Button from 'components/Button';
import Alert from 'components/Alert';
import { Modal, ModalContent, ModalHeader } from 'components/Modal';
import { IMAGE_MIME_TYPE } from 'constant';
import WebCam from 'components/WebCam';
import popup from 'utils/popup';
import PhotoContext from 'context/photo.context';
import { sanitizeSelfieImage } from 'utils/file';
import { handleHttpError } from 'utils/httpError';
import { setSelfiePhoto } from 'actions/photo.action';
import api from 'api';
import HttpRequest from 'utils/httpRequest';

function PhotoWithCardModal({ isOpen, onCancel, onUploaded }) {
  const [photo, dispatchPhoto] = useContext(PhotoContext);

  const webcamRef = useRef();

  // Gambar yang baru ditangkap.
  const [capturedImage, setCapturedImage] = useState(null);
  const [loading, setLoading] = useState(false);

  // Tangkap gambar.
  const capture = () => {
    const image = webcamRef.current.getScreenshot();
    setCapturedImage(image);
  };

  // Menghapus hasil tangkapan sebelumnya dan melakukan foto ulang.
  const recapture = () => {
    setCapturedImage(null);
  };

  // Membatalkan ubah foto.
  const handleCloseButtonClick = () => {
    setCapturedImage(null);
    onCancel();
  };

  // Mengupload atau memperbarui hasil foto yang baru ditangkap.
  const handlePhotoWithCardSave = useCallback(async () => {
    if (!capturedImage) return;

    setLoading(true);

    try {
      const data = new FormData();
      data.append('photo', await sanitizeSelfieImage(capturedImage));

      const response = await new HttpRequest(api.exam.postSelfieWithCardPhoto())
        .setData(data)
        .call();

      dispatchPhoto(setSelfiePhoto(response.data.data.photo));
      popup.showSuccessWithTimer(null, response.data.message);
      onUploaded();
    } catch (err) {
      handleHttpError(err);
    }

    setLoading(false);
  }, [capturedImage, dispatchPhoto, onUploaded]);

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>Foto Dengan Kartu</ModalHeader>
      <ModalContent>
        {!photo.selfie && (
          <Alert color="warning">
            Anda belum mengunggah foto dengan kartu ujian saat ini. Anda{' '}
            <strong>harus</strong> mengunggah{' '}
            <strong>foto dengan kartu ujian</strong> sebelum memulai ujian.
          </Alert>
        )}

        {capturedImage ? (
          // Hasil foto
          <div className="mt-3">
            <img
              className="w-full"
              alt="Foto dengan kartu"
              src={capturedImage}
            />
            <div className="mt-3 flex justify-center gap-2">
              {/* Back button */}
              <Button onClick={handleCloseButtonClick} color="secondary">
                Kembali
              </Button>

              {/* Recapture button */}
              <Button color="secondary" onClick={recapture}>
                Foto Ulang
              </Button>

              {/* Save button */}
              <Button
                onClick={handlePhotoWithCardSave}
                loading={loading}
                disabled={loading}
              >
                Simpan
              </Button>
            </div>
          </div>
        ) : (
          // Layar kamera
          <div className="mt-3">
            <WebCam
              ref={webcamRef}
              controls={false}
              screenshotFormat={IMAGE_MIME_TYPE}
            />

            <div className="flex justify-center gap-3 mt-3">
              {/* Back button */}
              <Button onClick={handleCloseButtonClick} color="secondary">
                Kembali
              </Button>

              {/* Take a picture button */}
              {/* Hidden */}
              <Button onClick={capture} className="hidden">
                Ambil Foto
              </Button>
            </div>
          </div>
        )}
      </ModalContent>
    </Modal>
  );
}

PhotoWithCardModal.propTypes = {
  isOpen: propTypes.bool.isRequired,
  onCancel: propTypes.func.isRequired,
  onUploaded: propTypes.func.isRequired,
};

export default PhotoWithCardModal;
