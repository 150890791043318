import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { createPortal } from 'react-dom';
import create from 'zustand';
import { v4 as uuidv4 } from 'uuid';
import { combine } from 'zustand/middleware';
import { useEffect, useRef, useState } from 'react';

import { SECOND } from 'constant';

// Portal alert root element
const alertRoot = document.getElementById('alert-root');

/**
 * @typedef {Object} Alert
 * @property {string} id
 * @property {'error'|'warning'|'info'|'success'} severity
 * @property {string} title
 * @property {number} destroyAt
 */

/**
 * @example
 * ```js
 * // If inside react element
 * function Element() {
 *   const alert = useAlert();
 *
 *   alert.show({
 *     severity: 'info',
 *     title: 'Hello WOrld',
 *   });
 *
 *   return <>Example</>;
 * }
 *
 * // If outside react element
 * useAlert.setState().show({
 *   severity: 'info',
 *   title: 'Hello WOrld',
 * });
 * ```
 */
const useAlert = create(
  combine(
    {
      /** @type {Array<Alert>} */
      alerts: [],
    },
    (set, get) => ({
      /**
       * Create new alert.
       * @param {{ title: string, severity: 'error'|'warning'|'info'|'success', destroyAt?: number }} alert
       * @returns {void}
       */
      show: ({ title, severity, destroyAt }) =>
        set({
          alerts: [
            ...get().alerts,
            {
              id: uuidv4(),
              title,
              severity,
              destroyAt: destroyAt || Date.now() + 10 * SECOND,
            },
          ],
        }),

      /**
       * Destroy specific alert by its id.
       * @param {string} id
       * @returns {void}
       */
      destroy: (id) =>
        set({ alerts: get().alerts.filter((alert) => alert.id !== id) }),
    })
  )
);

/**
 * Call this component only once in the project, because it's this hook has some
 * side effects.
 */
export const AlertHandler = () => {
  const { alerts, destroy } = useAlert((state) => ({
    alerts: state.alerts,
    destroy: state.destroy,
  }));

  const tickerRef = useRef(/** @type {NodeJS.Timer} */ (null));
  const [ticker, setTicker] = useState(0);

  // Memulai ticker.
  useEffect(() => {
    tickerRef.current = setInterval(
      () => setTicker((currentTicker) => currentTicker + 1),
      1 * SECOND
    );
    return () => clearInterval(tickerRef.current);
  }, []);

  // Menghapus alert yang sudah expired.
  useEffect(() => {
    const currentTime = Date.now();

    alerts.forEach(({ id, destroyAt }) => {
      if (destroyAt < currentTime) {
        destroy(id);
      }
    });
    // Hanya membutuhkan ticker saja.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticker]);

  return createPortal(
    alerts.length > 0 ? (
      <Stack spacing={2}>
        {alerts.reverse().map(({ id, severity, title }) => (
          <Alert
            key={id}
            severity={severity}
            variant="filled"
            closeText="Tutup"
            className="pointer-events-auto"
            onClose={() => destroy(id)}
          >
            {title}
          </Alert>
        ))}
      </Stack>
    ) : null,
    alertRoot
  );
};

export default useAlert;
