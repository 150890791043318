/* eslint-disable camelcase, no-async-promise-executor */
import propTypes from 'prop-types';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Promise } from 'bluebird';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useBeforeunload } from 'react-beforeunload';

import simulationQuestions from 'resources/simulation.json';
import { EXAM_PACKET_STARTED, EXAM_PACKET_FINISHED, SECOND } from 'constant';
import configuration from 'configuration';
import QuestionModel from 'models/question.model';
import { setExamStageHeader } from 'utils/authorization';
import * as examHelpers from 'utils/exam';
import HttpRequest from 'utils/httpRequest';
import api from 'api';
import {
  isStageChangedError,
  handleHttpError,
  isExamOverError,
} from 'utils/httpError';
import ExamContext from 'context/exam.context';
import { setExam } from 'actions/exam.action';
import {
  subscribeExamIsOverEvent,
  subscribeStageChangedEvent,
  unsubscribeExamIsOverEvent,
  unsubscribeStageChangedEvent,
} from 'utils/events';
import { dataURItoBlob } from 'libs/file';
import useStacker from 'hooks/useStacker';
import { call } from 'utils/caller';
import useAlert from 'hooks/useAlert';
import { isElectron } from 'utils/electron';

const convertToGroupedQuestions = (questions) =>
  questions.reduce((prev, curr) => {
    prev.push([curr]);
    return prev;

    // // eslint-disable-next-line no-param-reassign
    // curr = JSON.parse(JSON.stringify(curr));

    // // Jika pertanyaan pertama.
    // if (prev.length === 0) return [[curr]];

    // // Jika stimulus_id pertanyaan saat ini null, maka push pertanyaan baru.
    // if (curr.stimulus_id === null) {
    //   prev.push([curr]);
    //   return prev;
    // }

    // // Jika stimulus_id pertanyaan saat ini sama dengan stimulus_id pertanyaan, maka tambahkan pertanyaan ke stimulus tersebut.
    // if (prev[prev.length - 1][0].stimulus_id === curr.stimulus_id) {
    //   prev[prev.length - 1].push(curr);
    // } else {
    //   prev.push([curr]);
    // }

    // return prev;
  }, []);

/**
 * @typedef ExamControllerState
 *
 * @property {boolean} isSimulation Apakah ujian ini adalah simulasi
 * @property {boolean} isStart Apakah sedang dalam sesi ujian
 * @property {boolean} isPaused Apakah waktu ujian sedang ditunda
 * @property {boolean} isReady Apakah semua data yang diperlukan untuk ujian sudah siap
 *
 * @property {number|undefined} examId ID dari ujian yang saat ini sedang dikerjakan
 * @property {number|undefined} duration Durasi dari ujian ini
 * @property {number|undefined} remainingTime Sisa waktu peserta dari ujian ini
 * @property {string[]|undefined} answers Jawaban dari peserta untuk ujian ini
 * @property {number[]|undefined} readTimes Waktu membaca soal ujian
 * @property {number[]|undefined} answerTimes Waktu menjawab soal ujian
 *
 * @property {import('../models/question.model').IQuestionModel[]|undefined} questions Kumpulan pertanyaan yang ada di ujian ini
 * @property {import('../models/question.model').IQuestionModel[]|undefined} groupedQuestions Kumpulan pertanyaan yang sudah di grouping berdasarkan stimulus
 * @property {number|undefined} currentQuestionIndex Posisi soal yang sedang dilihat oleh peserta
 */

/**
 * @typedef ExamControllerActions
 *
 * @property {(examId: number) => Promise} start
 * @property {() => void} startSimulation
 * @property {() => void} pause
 * @property {() => void} resume
 * @property {(index: number, answer: string) => void} answer
 * @property {() => Promise<void>} updateAnswer
 * @property {() => void} finish
 * @property {() => void} reset
 *
 * @property {() => Promise<void>} refreshCurrentQuestion
 *
 * @property {() => void} prevQuestion
 * @property {() => void} nextQuestion
 * @property {(index: number) => void} goToQuestionIndex
 * @property {() => import('../models/question.model').IQuestionModel|null} getCurrentQuestion
 */

/** @type {ExamControllerState} */
const initialState = {
  isStart: false,
  isSimulation: false,
  isPaused: false,
  isReady: false,

  examId: undefined,
  duration: undefined,
  remainingTime: undefined,
  answers: undefined,
  readTimes: undefined,
  answerTimes: undefined,

  questions: undefined,
  groupedQuestions: undefined,
  currentQuestionIndex: undefined,
};

/**
 * Tempat untuk menyimpan state dan mengelola data ujian dan jawaban pada saat
 * ujian berlangsung.
 * @type {import('react').Context<[ExamControllerState, ExamControllerActions]>}
 */
const ExamControllerContext = createContext([initialState, undefined]);

export function ExamControllerContextProvider({ children }) {
  const history = useHistory();
  const loadingStack = useStacker();
  const [, dispatchExam] = useContext(ExamContext);

  const remainingTimeRef = useRef(
    /** @type {NodeJS.Timer|undefined} */ (undefined)
  );

  /**
   * State utama exam controller.
   */
  const [state, setState] = useState(initialState);

  /**
   * Mendapatkan data pertanyaan saat ini.
   */
  const getCurrentQuestion = useCallback(
    /**
     * @returns {import('models/question.model').IQuestionModel|null}
     */
    () => {
      if (state.isReady) {
        return state.questions[state.currentQuestionIndex];
      }
      return null;
    },
    [state.currentQuestionIndex, state.isReady, state.questions]
  );

  const getCurrentStimulusQuestions = useCallback(() => {
    if (state.isReady) {
      return state.groupedQuestions[state.currentQuestionIndex];
    }

    return null;
  }, [state.currentQuestionIndex, state.groupedQuestions, state.isReady]);

  /**
   * Mereset state exam controller seperti semula.
   */
  const reset = useCallback(
    /**
     * @returns {void}
     */
    () => {
      setState(initialState);
      call(loadingStack.reset);
    },
    [loadingStack.reset]
  );

  /**
   * Memulai simulasi ujian.
   */
  const startSimulation = useCallback(
    /**
     * @returns {void}
     */
    () => {
      const questions = simulationQuestions.map((q, i) =>
        new QuestionModel(q, i).setClassForHighlightableImage()
      );
      const groupedQuestions = convertToGroupedQuestions(questions);

      setState((currentState) => ({
        ...currentState,
        isSimulation: true,
        isStart: true,
        isReady: true,

        examId: 1, // dummy id
        duration: configuration.SIMULATE_DURATION,
        remainingTime: configuration.SIMULATE_DURATION,
        questions,
        groupedQuestions,

        currentQuestionIndex: 0,
        answers: Array(questions.length).fill(' '), // Array of empty strings (' ')
        readTimes: Array(questions.length).fill(0), // Array of numbers (0)
        answerTimes: Array(questions.length).fill(0), // Array of numbers (0)
      }));
    },
    []
  );

  /**
   * Menyiapkan semua kebutuhan ujian yang akan dikerjakan.
   */
  const fetchAllNeeds = useCallback(
    /**
     * @returns {Promise<void>}
     */
    async () => {
      // eslint-disable-next-line prefer-destructuring
      const examId = state.examId;

      // eslint-disable-next-line no-shadow, one-var
      let exam, answer, questions;

      // Mendapatkan data ujian berdasarkan stage.
      try {
        exam = await new HttpRequest(
          api.exam.getExamPacketByStage(examId)
        ).call();
        exam = exam.data.data;
      } catch (err) {
        handleHttpError(err);
        setState(initialState);
        return;
      }

      setExamStageHeader(exam.paket_ujian.sta);

      // Mendapatkan data jawaban.
      try {
        answer = await new HttpRequest(api.exam.getAnswer(examId)).call();
        answer = answer.data.data;
      } catch (err) {
        handleHttpError(err);
        setState(initialState);
        return;
      }

      // Mendapatkan data pertanyaan.
      try {
        questions = await new HttpRequest(api.exam.getQuestions(examId)).call();
        questions = questions.data.data;
      } catch (err) {
        handleHttpError(err);
        setState(initialState);
        return;
      }

      questions = questions.map((q, i) =>
        new QuestionModel(q, i).setClassForHighlightableImage()
      );
      const groupedQuestions = convertToGroupedQuestions(questions);

      setState((currentState) => ({
        ...currentState,
        isReady: true,
        currentQuestionIndex: 0,
        duration: exam.ujian.sta,
        remainingTime: examHelpers.getRemainingTime(exam),
        questions,
        groupedQuestions,
        answers: answer.jwb,
        readTimes: answer.wth,
        answerTimes: answer.wtj,
      }));
    },
    [state.examId]
  );

  /**
   * Memperbarui ujian yang sedang dikerjakan saat ini dan akan memperbarui exam
   * context juga.
   */
  const refreshCurrentExam = useCallback(
    /**
     * @returns {Promise<object>}
     */
    async () => {
      try {
        const response = await new HttpRequest(
          api.exam.getExamPacketById(state.examId)
        ).call();
        dispatchExam(setExam(response.data.data));
        return Promise.resolve(response.data.data);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    [dispatchExam, state.examId]
  );

  /**
   * Memulai ujian berdasarkan id ujian.
   */
  const start = useCallback(
    /**
     * @param {number} examId ID dari ujian yang akan dimulai.
     * @returns {Promise<void>}
     */
    async (examId) => {
      try {
        await new HttpRequest(api.exam.start(examId)).call();
        setState({
          ...initialState,
          isStart: true,
          examId,
        });
        call(loadingStack.reset);
        return Promise.resolve();
      } catch (err) {
        handleHttpError(err);
        return Promise.reject();
      }
    },
    [loadingStack.reset]
  );

  /**
   * Menambahkan stack pada loading yang akan berguna sebagai indikator pause.
   */
  const pause = useCallback(
    /**
     * @returns {void}
     */
    () => {
      if (state.isStart) {
        call(loadingStack.add);
      }
    },
    [loadingStack.add, state.isStart]
  );

  /**
   * Mengurangi stack pada loading yang akan berguna sebagai indikator pause.
   */
  const resume = useCallback(
    /**
     * @returns {void}
     */
    () => {
      if (state.isStart) {
        call(loadingStack.done);
      }
    },
    [loadingStack.done, state.isStart]
  );

  /**
   * Berpindah soal berdasarkan index.
   */
  const goToQuestionIndex = useCallback(
    /**
     * @param {number} index Target dari index pertanyaan yang akan ditampilkan.
     * @returns {void}
     */
    (index) => {
      if (state.isReady) {
        setState((currentState) => ({
          ...currentState,
          currentQuestionIndex: index,
        }));
      }
    },
    [state.isReady]
  );

  /**
   * Go to next question.
   */
  const nextQuestion = useCallback(
    /**
     * @returns {void}
     */
    () => {
      if (state.isReady) {
        setState((currentState) => ({
          ...currentState,
          currentQuestionIndex: currentState.currentQuestionIndex + 1,
        }));
      }
    },
    [state.isReady]
  );

  /**
   * Go to previous question.
   */
  const prevQuestion = useCallback(
    /**
     * @returns {void}
     */
    () => {
      if (state.isReady) {
        setState((currentState) => ({
          ...currentState,
          currentQuestionIndex: currentState.currentQuestionIndex - 1,
        }));
      }
    },
    [state.isReady]
  );

  /**
   * Mendapatkan rekomendasi index dari pertanyaan yang belum dijawab.
   */
  // const getRecommendedUnansweredQuestion = useCallback(
  //   /**
  //    * @param {number} skipIndex Index dari pertanyaan saat ini yang nantinya akan diskip supaya tidak masuk dalam rekomendasi.
  //    * @returns {number} Index dari pertanyaan yang disarankan.
  //    */
  //   (skipIndex = -1) => {
  //     // Mencari index pertanyaan yang belum dijawab berdasarkan pertanyaan
  //     // setelah pertanyaan ini.
  //     const afterCurrentIndex = state.answers.findIndex(
  //       (answer, index) =>
  //         answer === ' ' && index !== skipIndex && index > skipIndex
  //     );

  //     if (afterCurrentIndex > -1) return afterCurrentIndex;

  //     // Mencari dari keseluruhan pertanyaan yang belum dijawab.
  //     return state.answers.findIndex(
  //       (answer, index) => answer === ' ' && index !== skipIndex
  //     );
  //   },
  //   [state.answers]
  // );

  /**
   * Ini adalah fungsi yang harus dipanggil ketika peserta mengubah jawaban.
   * Fungsi ini digunakan untuk mengubah jawaban dan melakukan upload attachment
   * untuk jawaban yang jawabannya berupa file.
   */
  const answer = useCallback(
    /**
     * @param {string} newAnswer Jawaban baru.
     * @returns {Promise<void>}
     */
    async (index, newAnswer) => {
      if (state.isReady) {
        const currentQuestion = getCurrentQuestion();
        const oldAnswer = state.answers[index];

        if (!state.isSimulation) {
          /**
           * Melakukan proses upload attachment.
           * @param {'cvs'|'rec'|'pdf'} type
           * @param {FormData} formData
           * @returns {Promise<any>}
           */
          const process = async (type, formData) => {
            try {
              pause();

              const apiTarget =
                // eslint-disable-next-line no-nested-ternary
                type === 'cvs'
                  ? api.exam.updateAnswerImage
                  : type === 'rec'
                  ? api.exam.updateAnswerAudio
                  : api.exam.updateAnswerPdf;

              const response = await new HttpRequest(
                apiTarget(state.examId, state.currentQuestionIndex)
              )
                .setData(formData)
                .call();

              resume();
              return Promise.resolve(response);
            } catch (err) {
              resume();
              handleHttpError(err);
              return Promise.reject(err);
            }
          };

          if (
            ['cvs', 'rec', 'pdf'].includes(currentQuestion.type) &&
            newAnswer !== ' '
          ) {
            let message = '';
            const data = new FormData();

            if (currentQuestion.type === 'cvs') {
              try {
                data.append('image', dataURItoBlob(newAnswer), 'canvas.png');
                const response = await process(currentQuestion.type, data);
                // eslint-disable-next-line no-param-reassign
                newAnswer = response.data.data.image;
                message = 'Gambar berhasil disimpan.';
              } catch (err) {
                return;
              }
            } else if (currentQuestion.type === 'rec') {
              try {
                data.append('audio', newAnswer, 'audio.webm');
                const response = await process(currentQuestion.type, data);
                // eslint-disable-next-line no-param-reassign
                newAnswer = response.data.data.audio;
                message = 'Rekaman berhasil disimpan.';
              } catch (err) {
                return;
              }
            } else if (currentQuestion.type === 'pdf') {
              try {
                data.append('file', newAnswer, 'document.pdf');
                const response = await process(currentQuestion.type, data);
                // eslint-disable-next-line no-param-reassign
                newAnswer = response.data.data.pdf;
                message = 'File berhasil disimpan.';
              } catch (err) {
                return;
              }
            }

            // Menampilkan pesan sukses.
            useAlert.getState().show({
              title: message,
              severity: 'success',
            });
          }
        }

        // Menampilkan informasi perubahan jawaban.
        console.info(
          `Change answer no. ${index + 1} from "${oldAnswer}" to "${newAnswer}"`
        );

        // Mmemperbarui jawaban.
        setState((currentState) => ({
          ...currentState,
          answers: currentState.answers.map((v, i) =>
            i === index ? newAnswer : v
          ),
          answerTimes: currentState.answerTimes.map((v, i) =>
            i === index ? currentState.readTimes[i] : v
          ),
        }));

        // Mendapatkan rekomendasi pertanyaan selanjutnya yang akan ditampilkan.
        // Pertanyaan akan diubah sesuai rekomendasi ketika pertanyaan saat ini
        // merupakan pertanyaan pilihan, jawaban baru tidak kosong dan jawaban
        // lama kosong.
        // Exam recommendation disabled.
        // if (
        //   currentQuestion.type === 'pil' &&
        //   newAnswer !== ' ' &&
        //   oldAnswer === ' '
        // ) {
        //   const unansweredIndex = getRecommendedUnansweredQuestion(
        //     state.currentQuestionIndex
        //   );
        //   if (unansweredIndex > -1) goToQuestionIndex(unansweredIndex);
        // }
      }
    },
    [
      state.isReady,
      state.answers,
      state.currentQuestionIndex,
      state.isSimulation,
      state.examId,
      getCurrentQuestion,
      pause,
      resume,
    ]
  );

  /**
   * Axios token yang digunakan untuk melakukan cancel apabila ada update answer
   * request lain yang dijalankan sebelum request update answer yang sebelumnya
   * selesai dilakukan.
   */
  const [updateAnswerToken, setUpdateAnswerToken] = useState(
    /** @type {import('axios').CancelTokenSource} */ (undefined)
  );

  /**
   * Fungsi ini akan dipanggil ketika jawaban peserta berubah dan ketika ujian
   * akan diselesaikan. Http request pada fungsi ini tidak akan dapat bertumpuk
   * karena ketika ada request baru, maka request sebelumnya akan dicancel
   * apabila request sebelumnya belum sukses dilakukan.
   */
  const updateAnswer = useCallback(
    /**
     * @returns {Promise<void>}
     */
    async () => {
      if (state.isSimulation || !state.isReady) return Promise.resolve();

      try {
        // Cancel request sebelumnya apabila ada.
        if (updateAnswerToken) updateAnswerToken.cancel();

        // Menambahkan token untuk request ini.
        const newToken = axios.CancelToken.source();
        setUpdateAnswerToken(newToken);

        const data = {
          answer: state.answers,
          remaining_time: state.remainingTime,
          answer_time: state.answerTimes,
          read_question_time: state.readTimes,
        };

        await new HttpRequest(api.exam.updateAnswer(state.examId))
          .setConfig({
            cancelToken: newToken.token,
          })
          .setData(data)
          .onFail(pause)
          .onSuccess(resume)
          .call();
      } catch (err) {
        if (!axios.isCancel(err)) {
          handleHttpError(err);
        }
      }

      return Promise.resolve();
    },
    [
      updateAnswerToken,
      pause,
      resume,
      state.answerTimes,
      state.answers,
      state.examId,
      state.isReady,
      state.isSimulation,
      state.readTimes,
      state.remainingTime,
    ]
  );

  /**
   * Merefresh data ujian saat ini.
   */
  const refreshCurrentQuestion = useCallback(
    /**
     * @returns {Promise<void>}
     */
    async () => {
      // eslint-disable-next-line prefer-destructuring
      const currentStimulusQuestions = getCurrentStimulusQuestions();
      const currentStimulusQuestionIds = currentStimulusQuestions.map(
        (q) => q.id
      );

      const stimulusId = currentStimulusQuestions[0].stimulus_id;
      const isSingleQuestion = currentStimulusQuestionIds.length === 1;

      try {
        const response = await (isSingleQuestion
          ? new HttpRequest(
              api.exam.getQuestion(state.examId, currentStimulusQuestions[0].id)
            ).call()
          : new HttpRequest(
              api.exam.getQuestionsByStimulus(state.examId, stimulusId)
            ).call());

        const { data } = response.data;

        setState((currentState) => {
          const questions = currentState.questions.map((question, index) => {
            if (isSingleQuestion && question.id === data.id) {
              return new QuestionModel(
                data,
                index
              ).setClassForHighlightableImage();
            }

            if (currentStimulusQuestionIds.includes(question.id)) {
              const q = data.find((item) => item.id === question.id);
              return new QuestionModel(
                q,
                index
              ).setClassForHighlightableImage();
            }

            return question;
          });
          const groupedQuestions = convertToGroupedQuestions(questions);

          return {
            ...currentState,
            questions,
            groupedQuestions,
          };
        });
      } catch (err) {
        handleHttpError(err);
      }
    },
    [getCurrentStimulusQuestions, state.examId]
  );

  /**
   * Menyelesaikan ujian. Fungsi ini memiliki beberapa tahap, diantaranya adalah
   * melakukan update jawaban terlebih dahulu, menyelesaikan ujian, mendapatkan
   * data paket ujian saat ini, dan apabila stagenya belum berakhir maka akan
   * dilanjutkan ke stage berikutnya.
   */
  const finish = useCallback(
    /**
     * @returns {Promise<void>}
     */
    async () => {
      if (state.isSimulation) reset();
      if (state.isSimulation || !state.isReady) return;

      // Pause waktu ujian.
      pause();

      // Cancel update answer jika ada.
      if (updateAnswerToken) updateAnswerToken.cancel();

      // Memperbarui jawaban.
      try {
        await updateAnswer();
      } catch (err) {
        handleHttpError(err);
      }

      // Menyelesaikan ujian
      try {
        await new HttpRequest(api.exam.finish(state.examId)).call();
      } catch (err) {
        handleHttpError(err);

        // Tidak melanjutkan proses selanjutnya karena error ini akan ditangani
        // oleh event listener.
        if (isExamOverError(err) || isStageChangedError(err)) {
          return;
        }
      }

      // Memperbarui data paket ujian saat ini.
      try {
        const exam = await refreshCurrentExam();
        dispatchExam(setExam(exam));

        // Jika masih ada stage selanjutnya.
        if (exam.paket_ujian.sta !== EXAM_PACKET_FINISHED) {
          useAlert.getState().show({
            title: 'Sesi ujian berakhir & lanjut ke stage berikutnya',
            severity: 'success',
          });

          // Mulai ujian untuk stage selanjutnya.
          start(state.examId);
          return;
        }
      } catch (err) {
        handleHttpError(err);
      }

      // Jika tidak ada sesi selanjutnya maka langsung reset controller.
      reset();
    },
    [
      state.isSimulation,
      state.isReady,
      state.examId,
      reset,
      pause,
      updateAnswerToken,
      updateAnswer,
      refreshCurrentExam,
      dispatchExam,
      start,
    ]
  );

  /**
   * Mengurangi sisa waktu.
   */
  const decrementRemainingTime = useCallback(
    /**
     * @returns {void}
     */
    () => {
      if (state.isReady) {
        setState((currentState) => ({
          ...currentState,
          // Mencegah pengurangan waktu saat terjadi pause.
          // eslint-disable-next-line no-nested-ternary
          remainingTime: currentState.isPaused
            ? currentState.remainingTime
            : currentState.remainingTime === 0
            ? 0
            : currentState.remainingTime - 1,
        }));
      }
    },
    [state.isReady]
  );

  /**
   * Menambahkan waktu melihat soal berdasarkan index pertanyaan saat ini.
   */
  const incrementReadQuestionTime = useCallback(
    /**
     * @returns {void}
     */
    () => {
      if (state.isReady) {
        setState((currentState) => ({
          ...currentState,
          // Mencegah penambahan waktu saat terjadi pause.
          readTimes: currentState.isPaused
            ? currentState.readTimes
            : currentState.readTimes.map((v, i) =>
                // Menambahkan 1 detik berdasarkan index pertanyaan.
                i === currentState.currentQuestionIndex ? v + 1 : v
              ),
        }));
      }
    },
    [state.isReady]
  );

  // Otomatis mengubah nilai dari state.isPaused ketika nilai dari
  // loadingStack.hasStack berubah.
  useEffect(() => {
    setState((currentState) => ({
      ...currentState,
      isPaused: call(loadingStack.hasStack),
    }));
  }, [loadingStack.hasStack]);

  // Mendapatkan semua data kebutuhan ujian seperti jawaban peserta, soal dan
  // sisa waktu saat ujian baru dimulai dan.
  useEffect(() => {
    if (state.isStart && !state.isReady) {
      fetchAllNeeds();
    }
  }, [fetchAllNeeds, state.isReady, state.isStart]);

  // Menangani pengurangan sisa waktu ujian setiap detik.
  useEffect(() => {
    if (!state.isReady) return;
    if (typeof remainingTimeRef.current === 'undefined') {
      remainingTimeRef.current = setInterval(() => {
        decrementRemainingTime();
      }, 1 * SECOND);
    }
  }, [decrementRemainingTime, state.isReady]);

  // Menghilangkan interval pengurangan detik saat ujian telah berakhir atau
  // saat baru berpindah stage.
  useEffect(() => {
    if (!state.isStart || !state.isReady) {
      clearInterval(remainingTimeRef.current);
      remainingTimeRef.current = undefined;
    }
  }, [state.examId, state.isReady, state.isStart]);

  // Menangani penambahan waktu melihat soal setiap detik.
  useEffect(() => {
    incrementReadQuestionTime();
  }, [incrementReadQuestionTime, state.remainingTime]);

  // Menghilangkan interval pengurangan detik saat komponen ditutup.
  useEffect(
    () => () => {
      clearInterval(remainingTimeRef.current);
      remainingTimeRef.current = undefined;
    },
    []
  );

  // Mengupdate jawaban saat komponen
  useEffect(
    () => () => {
      clearInterval(remainingTimeRef.current);
      remainingTimeRef.current = undefined;
    },
    []
  );

  // Mengupdate jawaban setiap ada jawaban yang dijawab atau diubah oleh peserta
  useEffect(() => {
    if (state.isReady && !state.isPaused) {
      updateAnswer();
    }
    // Hanya membutuhkan dependency state.answer karena fungsi ini hanya akan
    // ditrigger saat jawaban berubah.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.answers]);

  // Menghentikan ujian saat waktu pengerjaan habis.
  useEffect(() => {
    if (state.isReady && state.remainingTime === 0) {
      finish();
    }
    // Hanya membutuhkan dependency state.remainingTime karena fungsi ini hanya
    // akan ditrigger saat sisa waktu berubah.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.remainingTime]);

  // Memanggil fungsi update answer ketika komponen ditutup.
  useBeforeunload((e) => {
    if (state.isStart && state.isReady && !isElectron()) {
      e.preventDefault();
      updateAnswer();
    }
  });

  // Melisten trigger stage telah diubah paksa.
  useEffect(() => {
    const handle = async (e) => {
      const newStage = e.value;

      if (EXAM_PACKET_STARTED.includes(newStage)) {
        useAlert.getState().show({
          title: `Stage diubah oleh petugas, anda dialihkan ke stage ${newStage}`,
          severity: 'success',
        });

        start(state.examId);
      } else {
        reset();
      }
    };
    subscribeStageChangedEvent(handle);
    return () => unsubscribeStageChangedEvent(handle);
  }, [reset, start, loadingStack.reset, state.examId]);

  // Melisten trigger ujian berakhir.
  useEffect(() => {
    const handle = async () => {
      if (!state.examId) return;

      refreshCurrentExam();
      useAlert.getState().show({
        title: 'Sesi ujian berakhir',
        severity: 'success',
      });
      reset();
      history.push('/dashboard');
    };

    subscribeExamIsOverEvent(handle);
    return () => unsubscribeExamIsOverEvent(handle);
  }, [reset, history, refreshCurrentExam, state.examId]);

  return (
    <ExamControllerContext.Provider
      value={[
        state,
        {
          start,
          startSimulation,
          pause,
          resume,
          answer,
          updateAnswer,
          finish,
          reset,
          getCurrentQuestion,
          goToQuestionIndex,
          nextQuestion,
          prevQuestion,
          refreshCurrentQuestion,
        },
      ]}
    >
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: 1059 /** satu level di bawah z-index sweetalert2 */,
        }}
        open={state.isPaused}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {children}
    </ExamControllerContext.Provider>
  );
}

ExamControllerContextProvider.propTypes = {
  children: propTypes.node.isRequired,
};

export default ExamControllerContext;
