import propTypes from 'prop-types';
import { useCallback, useContext } from 'react';
import { FaRegQuestionCircle } from 'react-icons/fa';

import HelpForm from './HelpForm';

import Alert from 'components/Alert';
import api from 'api';
import UserContext from 'context/user.context';
import popup from 'utils/popup';
import { handleHttpError } from 'utils/httpError';
import Typography from 'components/Typography';
import HttpRequest from 'utils/httpRequest';

function Help({
  userId,
  userCode,
  userName,
  questionStatus,
  question,
  questionCategory,
  answer,
  onSuccess,
}) {
  const [user] = useContext(UserContext);

  const getCategoryText = useCallback((id) => {
    switch (id) {
      case 1:
        return 'Masalah login';
      case 2:
        return 'Masalah koneksi';
      case 3:
        return 'Masalah tiba-tiba berhenti';
      case 4:
        return 'Lainnya';
      default:
        return 'Kategori tidak diketahui';
    }
  }, []);

  const handleSubmit = useCallback(
    async (values, { setSubmitting, setErrors, resetForm }) => {
      try {
        const newValues = Object.assign(values, {});
        newValues.category = parseInt(newValues.category, 10);
        newValues.user_id = userId || user.id;
        await new HttpRequest(api.help.askQuestion()).setData(newValues).call();
        resetForm();
        onSuccess();

        popup.showSuccessWithTimer(
          null,
          'Anda berhasil membuat laporan. Mohon ditunggu jawabannnya'
        );
      } catch (err) {
        if (err.response.status === 400) setErrors(err.response.data.errors);
        else handleHttpError(err);
      } finally {
        setSubmitting(false);
      }
    },
    [onSuccess, user.id, userId]
  );

  return (
    <>
      <Alert
        title={<span className="text-gray-500 font-medium">Helpdesk</span>}
        color="success"
      >
        <Typography className="font-semibold mt-2">Ruang Ujian CBT</Typography>
        <FaRegQuestionCircle className="absolute right-5 top-9 object-center text-4xl text-green-200" />
      </Alert>

      <div className="mt-5">
        <Typography className="text-center font-semibold">
          {userName || user.nama}
        </Typography>
        <Typography className="font-normal text-center text-gray-400 mt-1">
          {userCode || user.kdr}
        </Typography>

        <div className="px-5">
          {[1, 2, 3, 4].includes(questionStatus || user.staTanya) && (
            <div className="bg-green-100 p-4 mt-4">
              <div>
                <Typography className="text-gray-500 font-normal">
                  Kategori masalah
                </Typography>
                <Typography className="font-normal">
                  {getCategoryText(questionCategory || user.katTanya)}
                </Typography>
              </div>
              <div className="mt-3">
                <Typography className="text-gray-500 font-normal">
                  Permasalahan
                </Typography>
                <Typography className="font-normal">
                  {question || user.textTanya}
                </Typography>
              </div>
              <div className="mt-3">
                <Typography className="text-green-500 font-normal">
                  Jawaban
                </Typography>
                <Typography className="font-normal">
                  {answer || user.textJawab || 'Belum dijawab'}
                </Typography>
              </div>
            </div>
          )}

          <div className="mt-3">
            <HelpForm onSubmit={handleSubmit} />
          </div>
        </div>
      </div>
    </>
  );
}

Help.defaultProps = {
  userId: null,
  userCode: null,
  userName: null,
  questionStatus: null,
  question: null,
  questionCategory: null,
  answer: null,
  onSuccess: () => {},
};

Help.propTypes = {
  userId: propTypes.number,
  userCode: propTypes.string,
  userName: propTypes.string,
  questionStatus: propTypes.number,
  question: propTypes.string,
  questionCategory: propTypes.number,
  answer: propTypes.string,
  onSuccess: propTypes.func,
};

export default Help;
