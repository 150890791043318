import { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
  NOTIFICATION_SERVICE_STARTED,
  START_NOTIFICATION_SERVICE,
  TOKEN_UPDATED,
} from 'electron-push-receiver/src/constants';

import useIsAuthenticated from './useIsAuthenticated';

import api from 'api';
import { FIREBASE_TOKEN_CHANGED, MINUTE } from 'constant';
import { setAuthorizationToken } from 'utils/authorization';
import messaging from 'utils/messaging';
import { isElectron } from 'utils/electron';
import configuration from 'configuration';
import HttpRequest from 'utils/httpRequest';

// Event akan dipanggil apabila terdapat perubahan firebase token pada local
// storage.
const firebaseTokenChangedEvent = new Event(FIREBASE_TOKEN_CHANGED);

/**
 * @description
 * Call this hook only once in the project, because it's this hook has some
 * side effects.
 */
export default function useTokenManagement() {
  const history = useHistory();
  const isAuthenticated = useIsAuthenticated();

  const refreshTokenRef = useRef(null);

  // Refresh authorization token.
  const refreshJwt = useCallback(
    () =>
      new HttpRequest(api.auth.refreshJwt())
        .setData({ time: Date.now() })
        .call()
        .then((res) => res.data)
        .then((data) => setAuthorizationToken(data.data.token))
        .catch(() => history.push('/logout')),
    [history]
  );

  // Mengubah `firebaseToken` milik pengguna.
  const updateFirebaseToken = useCallback(
    (firebaseToken) =>
      new HttpRequest(api.profile.updateFirebaseToken())
        .setData({ firebaseToken })
        .call(),
    []
  );

  // Mengubah nilai dari `firebaseToken` yang ada di local storage dan
  // men-trigger event.
  const handleFirebaseTokenChange = useCallback((firebaseToken) => {
    localStorage.setItem('firebaseToken', firebaseToken);
    firebaseTokenChangedEvent.value = firebaseToken;
    window.dispatchEvent(firebaseTokenChangedEvent);
  }, []);

  // Melakukan refresh token dalam interval waktu 7 menit.
  useEffect(() => {
    if (isAuthenticated) {
      refreshTokenRef.current = setInterval(refreshJwt, 7 * MINUTE);
    } else {
      clearInterval(refreshTokenRef.current);
    }

    return () => clearInterval(refreshTokenRef.current);
  }, [refreshJwt, isAuthenticated]);

  // Menangani firebase token pada saat dijalankan di website.
  useEffect(() => {
    if (isElectron()) return;

    messaging?.getToken().then((token) => handleFirebaseTokenChange(token));
  }, [handleFirebaseTokenChange, isAuthenticated, updateFirebaseToken]);

  // Menangani apabile terdapat perubahan token yang dikirim dari electron.
  useEffect(() => {
    if (!isElectron()) return;

    const handle = (_, token) => handleFirebaseTokenChange(token);

    window.ipcRenderer.on(NOTIFICATION_SERVICE_STARTED, handle);
    window.ipcRenderer.on(TOKEN_UPDATED, handle);

    // Mendapatkan token saat pertama kali aplikasi dibuka.
    window.ipcRenderer.send(
      START_NOTIFICATION_SERVICE,
      configuration.FIREBASE_SENDER_ID
    );

    // eslint-disable-next-line consistent-return
    return () => {
      window.ipcRenderer.removeListener(TOKEN_UPDATED, handle);
      window.ipcRenderer.removeListener(NOTIFICATION_SERVICE_STARTED, handle);
    };
  }, [handleFirebaseTokenChange]);

  // Menangani apabila terdapat perubahan token pada localstorage.
  useEffect(() => {
    const handle = (e) => isAuthenticated && updateFirebaseToken(e.value);
    window.addEventListener(FIREBASE_TOKEN_CHANGED, handle);

    return () => window.removeEventListener(FIREBASE_TOKEN_CHANGED, handle);
  }, [isAuthenticated, updateFirebaseToken]);

  useEffect(() => {
    if (isAuthenticated) {
      updateFirebaseToken(localStorage.getItem('firebaseToken'));
    }
  }, [isAuthenticated, updateFirebaseToken]);
}
