/* eslint-disable react/prop-types */
import { forwardRef } from 'react';

import ExamPopup from './ExamPopup';

import { safeJoinURL } from 'libs/url';
import Typography from 'components/Typography';
import useBaseURL from 'hooks/useBaseURL';
import clsx from 'libs/clsx';
// import Input from 'components/Input';

function ExamQuestionDragPopup({ question, answer, onAnswerChange }, ref) {
  const path = useBaseURL();

  // Apakah pertanyaan memiliki karakter [img],
  const questionContainImg = /\[img\]/i.test(question.question);

  const isGBRContainMp3 = /\.(?:wav|mp3)$/i.test(question.image);

  // Mengubah mathML asli menjadi span.
  // const htmlTtbMathML = useMemo(
  //   () =>
  //     // const regexMathML = /<math(.*?<\/math>)/g;

  //     // Menambahkan string mathML ke dalam state.
  //     // setMathElements(question.additionalQuestion.match(regexMathML) || []);

  //     question.additionalQuestion.replace(/@url\//g, `${path.questionImage}s/`),

  //   // Mereplace mathML menjadi span.
  //   // let index = 0;
  //   // return additionalQuestionString.replace(
  //   //   regexMathML,
  //   //   // eslint-disable-next-line no-plusplus
  //   //   () => `<span mathjax="true" index="${index++}"></span>`
  //   // );
  //   [path.questionImage, question.additionalQuestion]
  // );

  // Replace [@url] dengan questionImage path.
  // let tempQuestionString = question.question.replace(
  //   /@url\//g,
  //   `${path.questionImage}p/`
  // );

  // Replace [@url] dengan questionImage path.
  // const additionalQuestionString = question.additionalQuestion.replace(
  //   /@url\//g,
  //   `${path.questionImage}s/`
  // );
  // const AnswerContainerKu = ExamFill;

  // const AnswerContainerKu = () => (
  //   <ExamFill
  //     ref={ref}
  //     question={question}
  //     answer={answer}
  //     onAnswerChange={onAnswerChange}
  //     resetAnswer={resetAnswer}
  //   />
  // );

  // const componentInput = <AnswerContainer ref={ref} question={question} answer={answer} onAnswerChange={onAnswerChange} resetAnswer={resetAnswer} />

  // Replace [@url] dengan questionImage path.
  // tempQuestionString = tempQuestionString.replace(
  //   /<@isian>/i,
  //   // ReactDOMServer.renderToString(<Input label="" />)
  //   ''
  // );

  // const questionString = tempQuestionString;
  // tempQuestionString = tempQuestionString.replace(/<([^>]+)>/i, '');
  // const partQuestions = tempQuestionString.split(/<@isian>/i);
  // //kalimat1 <isian> kalimat2
  // //[kalimat1, kalimat2]

  const questionArabic = /[\u0600-\u06FF]/.test(question.question);
  // const questionTtbArabic = /[\u0600-\u06FF]/.test(question.additionalQuestion);

  // const items = partQuestions;
  // const itemList = [];
  // const juis = (tempQuestionString.match(/<@isian>/gi) || []).length;
  // // const itemDonk = [];
  // let i = 1;
  // let countjuis = 0;
  // items.forEach((item) => {
  //   itemList[i] = (
  //     <span
  //       dir="auto"
  //       className={clsx(questionArabic && 'xl:text-3xl text-2xl')}
  //       style={{
  //         fontFamily: questionArabic ? 'Traditional Arabic' : 'inherit',
  //       }}
  //       // eslint-disable-next-line react/no-danger
  //       dangerouslySetInnerHTML={{
  //         __html: `${item}`,
  //       }}
  //     />
  //   );
  //   i += 1;
  //   if (countjuis < juis) {
  //     itemList[i] = `@input|${countjuis}`;
  //     countjuis += 1;
  //   }
  //   i += 1;
  // });
  return (
    <>
      <div className="flex justify-end items-center">
        {/* <Typography
          variant="subtitle1"
          className="font-medium mt-8"
          gutterBottom
        >
          Pertanyaan
        </Typography> */}

        {/* <Button
          type="button"
          color="danger"
          size="sm"
          onClick={onResetAnswer}
          disabled={!canResetAnswer}
        >
          Batalkan Jawaban
        </Button> */}
      </div>

      {/* Paragraf tambahan */}
      {/* {additionalQuestionString && (
        <Typography
          as="div"
          className="font-readable select-none mt-5"
          gutterBottom
        >
          <div
            dir="auto"
            className={clsx(questionArabic && 'xl:text-3xl text-2xl')}
            style={{
              fontFamily: questionArabic ? 'Traditional Arabic' : 'inherit',
            }}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: additionalQuestionString,
            }}
          />
        </Typography>
      )} */}

      {/* Paragraf tambahan */}
      {/* {htmlTtbMathML && (
        <Typography
          as="div"
          className={clsx(
            'font-readable select-none mt-5',
            questionTtbArabic && 'xl:text-3xl text-2xl'
          )}
          dir="auto"
          style={{
            fontFamily: questionTtbArabic ? 'Traditional Arabic' : 'inherit',
          }}
          gutterBottom
        >
          <MathJax>
            <span dangerouslySetInnerHTML={{ __html: htmlTtbMathML }} />
          </MathJax>
          {ReactHtmlParser(htmlTtbMathML, {
            // eslint-disable-next-line consistent-return
            transform: (node) => {
              if (node.name === 'span' && node.attribs?.mathjax === 'true') {
                const index = parseInt(node.attribs.index, 10);
                const mathElement = mathElements[index];
                // console.log(index);
                if (typeof mathElement === 'string') {
                  return (
                    <MathJax
                      key={uuidv4()}
                      math={String.raw`${mathElement}`}
                      style={{ display: 'inline-block' }}
                    />
                  );
                }
              }
            },
          })}
        </Typography>
      )} */}

      {/* Pertanyaan */}
      <Typography
        as="div"
        className="font-readable select-none mt-5"
        gutterBottom
      >
        <ExamPopup
          ref={ref}
          question={question}
          answer={answer}
          onAnswerChange={onAnswerChange}
        />
      </Typography>

      {/* Petunjuk */}
      {question.instruction && (
        <Typography
          as="div"
          className="instruction font-readable bg-gray-300 py-3 px-4 rounded-md shadow-md select-none mt-5"
        >
          <div
            dir="auto"
            className={clsx(questionArabic && 'xl:text-3xl text-2xl')}
            style={{
              fontFamily: questionArabic ? 'Traditional Arabic' : 'inherit',
            }}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: question.instruction,
            }}
          />
        </Typography>
      )}

      {/* Gambar tambahan */}
      {question.image && !questionContainImg && !isGBRContainMp3 && (
        <img
          className="w-auto max-w-full mt-5 block image-highlightable"
          src={safeJoinURL(path.questionImage, question.image)}
          alt={question.image}
        />
      )}

      {/* Mp3 tambahan */}
      {question.image && !questionContainImg && isGBRContainMp3 && (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <audio
          src={safeJoinURL(path.questionImage, question.image)}
          controls
          preload="auto"
          controlsList="nofullscreen nodownload noremoteplayback noplaybackrate"
          className="mx-auto"
        />
      )}
    </>
  );
}

ExamQuestionDragPopup = forwardRef(ExamQuestionDragPopup);

export default ExamQuestionDragPopup;
