import propTypes from 'prop-types';
import { createContext, useReducer } from 'react';

import photoReducer, { initialPhotoState } from 'reducers/photo.reducer';

const PhotoContext = createContext();

export function PhotoContextProvider({ children }) {
  return (
    <PhotoContext.Provider value={useReducer(photoReducer, initialPhotoState)}>
      {children}
    </PhotoContext.Provider>
  );
}

PhotoContextProvider.propTypes = {
  children: propTypes.node.isRequired,
};

export default PhotoContext;
