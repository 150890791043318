import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import propTypes from 'prop-types';

import Container from './Container';

import clsx from 'libs/clsx';
import { isImage } from 'utils/exam';
import { safeJoinURL } from 'libs/url';
import useBaseURL from 'hooks/useBaseURL';
import Typography from 'components/Typography';

function Draggable({ id, className, label }) {
  const path = useBaseURL();
  const { attributes, listeners, setNodeRef, transform } = useDraggable({ id });

  const style = {
    transform: CSS.Translate.toString(transform),
  };

  return (
    <Container
      ref={setNodeRef}
      style={style}
      className={clsx(
        'cursor-move bg-white border-4 border-gray-200 text-center flex flex-wrap justify-center content-center p-2',
        className
      )}
      {...listeners}
      {...attributes}
    >
      {isImage(label) ? (
        <img
          alt={label}
          src={safeJoinURL(path.questionImage, label)}
          // @todo menyesuaikan lebar dan tinggi ini.
          className="max-w-full max-h-full w-auto h-auto"
        />
      ) : (
        <Typography
          as="div"
          className="font-readable"
          dangerouslySetInnerHTML={{
            __html: label,
          }}
        />
      )}
    </Container>
  );
}

// Untuk slot draggable yang itemnya sudah dipindah ke kiri.
export function DraggableSlot() {
  return <Container className="bg-gray-100 border-2 border-gray-200" />;
}

Draggable.defaultProps = {
  className: '',
};

Draggable.propTypes = {
  id: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
  className: propTypes.string,
};

export default Draggable;
