import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
// eslint-disable-next-line import/no-unresolved
import * as Sentry from '@sentry/react';
// eslint-disable-next-line import/no-unresolved
import { Integrations } from '@sentry/tracing';
import { MathJaxContext } from 'better-react-mathjax';

import 'typeface-poppins';
import 'assets/fonts/HEY JUNE.otf';
import 'utils/messaging';
import 'index.css';
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import ContextContainer from 'context';
import {
  setAuthorizationToken,
  unsetAuthorizationToken,
} from 'utils/authorization';
import api from 'api';
import registerServiceWorker from 'serviceWorker';
import { setDefaultUserState } from 'reducers/user.reducer';
import HttpRequest from 'utils/httpRequest';
import Fallback from 'components/Fallback';

Sentry.init({
  dsn: '',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  initialScope: {
    tags: { app: 'react' },
  },
  environment: 'production',
});

function renderApp() {
  ReactDOM.render(
    <React.StrictMode>
      <Sentry.ErrorBoundary fallback={Fallback}>
        <HashRouter>
          <ContextContainer>
            <MathJaxContext>
              <App />
            </MathJaxContext>
          </ContextContainer>
        </HashRouter>
      </Sentry.ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

/**
 * Fungsi yang digunakan untuk mendapatkan data dari user ketika halaman
 * baru pertama kali dimuat atau halaman baru direload.
 */
// eslint-disable-next-line func-names
(async function () {
  const token = localStorage.getItem('token');

  // Jika ada token pada `localStorage`
  if (token) {
    // Memperbariu Header Authorization
    setAuthorizationToken(token);

    try {
      // Me-request data diri
      const responseProfile = await new HttpRequest(api.profile.detail())
        .silentUnauthorized()
        .call();

      // Mendapatkan data token terbaru.
      const responseToken = await new HttpRequest(api.auth.refreshJwt())
        .setData({ time: Date.now() })
        .call();

      setAuthorizationToken(responseToken.data.data.token);

      // Jika request tidak gagal, maka ubah nilai initial state pada
      // `UserContext`
      setDefaultUserState(responseProfile.data.data);
    } catch (err) {
      // Jika gagal, maka hapus Header Authorization
      unsetAuthorizationToken();
    }
  }

  renderApp();
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
registerServiceWorker();
