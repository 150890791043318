/* eslint-disable react/prop-types */
import { useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
// import ReactHtmlParser from 'react-html-parser';
import { MathJax } from 'better-react-mathjax';

import { safeJoinURL } from 'libs/url';
import Typography from 'components/Typography';
import useBaseURL from 'hooks/useBaseURL';
import clsx from 'libs/clsx';
import Input from 'components/Input';

function ExamQuestion({ question }) {
  const path = useBaseURL();

  // Apakah pertanyaan memiliki karakter [img],
  const questionContainImg = /\[img\]/i.test(question.question);

  const isGBRContainMp3 = /\.(?:wav|mp3)$/i.test(question.image);

  // Mengubah mathML asli menjadi span.
  // const htmlTtbMathML = useMemo(
  //   () =>
  //     // const regexMathML = /<math(.*?<\/math>)/g;

  //     // Menambahkan string mathML ke dalam state.
  //     // setMathElementsTtb(question.additionalQuestion.match(regexMathML) || []);

  //     question.additionalQuestion.replace(/@url\//g, `${path.questionImage}s/`),

  //   // Mereplace mathML menjadi span.
  //   // let index = 0;
  //   // return additionalQuestionString.replace(
  //   //   regexMathML,
  //   //   // eslint-disable-next-line no-plusplus
  //   //   () => `<span mathjax="true" index="${index++}"></span>`
  //   // );
  //   [path.questionImage, question.additionalQuestion]
  // );

  const htmlTksMathML = useMemo(() => {
    // const regexMathML = /<math(.*?<\/math>)/g;

    // Menambahkan string mathML ke dalam state.
    // setMathElementsTks(question.question.match(regexMathML) || []);

    // Replace [@url] dengan questionImage path.
    const tempQuestionString = question.question.replace(
      /@url\//g,
      `${path.questionImage}p/`
    );

    // Replace [@url] dengan questionImage path.
    return tempQuestionString.replace(
      /<@isian>/i,
      ReactDOMServer.renderToString(<Input id="" label="" />)
    );

    // Mereplace mathML menjadi span.
    // let index = 0;
    // return tempQuestionString.replace(
    //   regexMathML,
    //   // eslint-disable-next-line no-plusplus
    //   () => `<span mathjax="true" index="${index++}"></span>`
    // );
  }, [path.questionImage, question.question]);

  const questionArabic = /[\u0600-\u06FF]/.test(question.question);
  // const questionTtbArabic = /[\u0600-\u06FF]/.test(question.additionalQuestion);

  return (
    <>
      <div className="flex justify-end items-center">
        {/* <Typography
          variant="subtitle1"
          className="font-medium mt-8"
          gutterBottom
        >
          Pertanyaan
        </Typography> */}

        {/* <Button
          type="button"
          color="danger"
          size="sm"
          onClick={onResetAnswer}
          disabled={!canResetAnswer}
        >
          Batalkan Jawaban
        </Button> */}
      </div>

      {/* Paragraf tambahan */}
      {/* {question.additionalQuestion && (
        <Typography
          as="div"
          className="font-readable select-none mt-5"
          gutterBottom
        >
          <div
            dir="auto"
            className={clsx(questionArabic && 'xl:text-3xl text-2xl')}
            style={{
              fontFamily: questionArabic ? 'Traditional Arabic' : 'inherit',
            }}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: question.additionalQuestion,
            }}
          />
        </Typography>
      )} */}

      {/* Paragraf tambahan */}
      {/* {htmlTtbMathML && (
        <Typography
          as="div"
          className={clsx(
            'font-readable select-none mt-5',
            questionTtbArabic && 'xl:text-3xl text-2xl'
          )}
          dir="auto"
          style={{
            fontFamily: questionTtbArabic ? 'Traditional Arabic' : 'inherit',
          }}
          gutterBottom
        >
          <MathJax>
            <span dangerouslySetInnerHTML={{ __html: htmlTtbMathML }} />
          </MathJax>
          {ReactHtmlParser(htmlTtbMathML, {
            // eslint-disable-next-line consistent-return
            transform: (node) => {
              if (node.name === 'span' && node.attribs?.mathjax === 'true') {
                const index = parseInt(node.attribs.index, 10);
                const mathElement = mathElementsTtb[index];
                // console.log(index);
                if (typeof mathElement === 'string') {
                  return (
                    <MathJax
                      math={String.raw`${mathElement}`}
                      style={{ display: 'inline-block' }}
                    />
                  );
                }
              }
            },
          })}
        </Typography>
      )} */}

      {/* Pertanyaan */}
      {/* <Typography
        as="div"
        className="font-readable select-none mt-5"
        gutterBottom
      >
        <div
          dir="auto"
          className={clsx(questionArabic && 'xl:text-3xl text-2xl')}
          style={{
            fontFamily: questionArabic ? 'Traditional Arabic' : 'inherit',
          }}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: questionString,
          }}
        />
      </Typography> */}

      {/* Pertanyaan */}
      {htmlTksMathML && (
        <Typography
          as="div"
          className={clsx(
            'font-readable select-none mt-5',
            questionArabic && 'xl:text-3xl text-2xl'
          )}
          dir="auto"
          style={{
            fontFamily: questionArabic ? 'Traditional Arabic' : 'inherit',
          }}
          gutterBottom
        >
          <MathJax>
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: htmlTksMathML }} />
          </MathJax>
          {/* {ReactHtmlParser(htmlTksMathML, {
            // eslint-disable-next-line consistent-return
            transform: (node) => {
              if (node.name === 'span' && node.attribs?.mathjax === 'true') {
                const index = parseInt(node.attribs.index, 10);
                const mathElement = mathElementsTks[index];
                // console.log(index);
                if (typeof mathElement === 'string') {
                  return (
                    <MathJax
                      math={String.raw`${mathElement}`}
                      style={{ display: 'inline-block' }}
                    />
                  );
                }
              }
            },
          })} */}
        </Typography>
      )}

      {/* Petunjuk */}
      {question.instruction && (
        <Typography
          as="div"
          className="instruction font-readable bg-gray-300 py-3 px-4 rounded-md shadow-md select-none mt-5"
        >
          <div
            dir="auto"
            className={clsx(questionArabic && 'xl:text-3xl text-2xl')}
            style={{
              fontFamily: questionArabic ? 'Traditional Arabic' : 'inherit',
            }}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: question.instruction,
            }}
          />
        </Typography>
      )}

      {/* Gambar tambahan */}
      {question.image && !questionContainImg && !isGBRContainMp3 && (
        <img
          className="w-auto max-w-full mt-5 block image-highlightable"
          src={safeJoinURL(path.questionImage, question.image)}
          alt={question.image}
        />
      )}

      {/* Mp3 tambahan */}
      {question.image && !questionContainImg && isGBRContainMp3 && (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <audio
          src={safeJoinURL(path.questionImage, question.image)}
          controls
          preload="auto"
          controlsList="nofullscreen nodownload noremoteplayback noplaybackrate"
          className="mx-auto"
        />
      )}
    </>
  );
}

export default ExamQuestion;
