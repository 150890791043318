/* eslint-disable react/prop-types */
import { useMemo } from 'react';

import { ReactComponent as BookOpenIcon } from 'assets/icons/book-open.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { secondsToText } from 'libs/time';
import Button from 'components/Button';
import Card from 'components/Card';
import Progress from 'components/Progress';
import {
  EXAM_STATUS_ACTIVE,
  EXAM_STATUS_DONE,
  EXAM_STATUS_NOT_ACTIVE,
  EXAM_STATUS_STAGE1,
  EXAM_STATUS_STAGE2,
  EXAM_STATUS_STAGE3,
  EXAM_STATUS_WRONG_DATE,
} from 'constant';
import Typography from 'components/Typography';

function StatusText({ status }) {
  switch (status) {
    case EXAM_STATUS_NOT_ACTIVE:
      return (
        <Typography
          as="span"
          variant="small"
          className="font-normal px-8 py-2 bg-gray-200 text-gray-800 rounded-full"
        >
          Tidak Aktif
        </Typography>
      );
    case EXAM_STATUS_WRONG_DATE:
      return (
        <Typography
          as="span"
          variant="small"
          className="font-normal px-8 py-2 bg-gray-200 text-gray-800 rounded-full"
        >
          Tanggal Tidak Sesuai
        </Typography>
      );
    case EXAM_STATUS_ACTIVE:
      return (
        <Typography
          as="span"
          variant="small"
          className="font-normal px-8 py-2 bg-green-100 text-primary rounded-full"
        >
          Aktif
        </Typography>
      );
    case EXAM_STATUS_DONE:
      return (
        <Typography
          as="span"
          variant="small"
          className="font-normal px-8 py-2 bg-yellow-200 text-yellow-700 rounded-full"
        >
          Selesai
        </Typography>
      );
    case EXAM_STATUS_STAGE1:
      return (
        <Typography
          as="span"
          variant="small"
          className="font-normal px-8 py-2 bg-yellow-200 text-yellow-700 rounded-full"
        >
          Stage 1
        </Typography>
      );
    case EXAM_STATUS_STAGE2:
      return (
        <Typography
          as="span"
          variant="small"
          className="font-normal px-8 py-2 bg-yellow-200 text-yellow-700 rounded-full"
        >
          Stage 2
        </Typography>
      );
    case EXAM_STATUS_STAGE3:
      return (
        <Typography
          as="span"
          variant="small"
          className="font-normal px-8 py-2 bg-yellow-200 text-yellow-700 rounded-full"
        >
          Stage 3
        </Typography>
      );
    default:
      return null;
  }
}

function ExamCard({
  name,
  // questionTotal,
  duration,
  remainingTime,
  status,
  // percentage,
  onStartClick,
  disableStartButton,
}) {
  const durationText = useMemo(() => secondsToText(duration), [duration]);
  const remainingTimeText = useMemo(
    () => secondsToText(remainingTime),
    [remainingTime]
  );

  return (
    <Card className="mt-8">
      <div className="flex items-center justify-between">
        <Typography variant="subtitle2" className="font-normal">
          {name}
        </Typography>
        <StatusText status={status} />
      </div>

      <div className="grid grid-cols-12 mt-3">
        <div className="col-span-10">
          <div className="grid grid-cols-3">
            <div>
              <Typography variant="subtitle3" color="muted">
                Soal
              </Typography>
              <Typography
                variant="subtitle3"
                className="flex items-center gap-2 font-normal mt-2"
              >
                <BookOpenIcon className="text-gray-600" />
                {/* {questionTotal} Soal */}-
              </Typography>
            </div>
            <div>
              <Typography variant="subtitle3" color="muted">
                Durasi
              </Typography>
              <Typography
                variant="subtitle3"
                className="flex items-center gap-2 font-normal mt-2"
              >
                <ClockIcon className="text-gray-600" />
                {durationText}
              </Typography>
            </div>
            <div>
              <Typography variant="subtitle3" color="muted">
                Sisa Waktu
              </Typography>
              <Typography
                variant="subtitle3"
                className="flex items-center gap-2 font-normal mt-2 text-danger"
              >
                <ClockIcon className="text-red-400" />
                {remainingTimeText === '' ? 'Habis' : remainingTimeText}
              </Typography>
            </div>
          </div>

          <div className="mt-4 flex items-center gap-3 xl:w-11/12">
            <Progress percentage={0} className="flex-grow m-0" />
            <Typography as="span" className="flex-none text-primary">
              {/* {percentage}% */}- %
            </Typography>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-12 mt-3">
        <div className="col-span-12 ">
          <Button
            color="primary"
            onClick={onStartClick}
            disabled={disableStartButton}
            className="right-0 bottom-0 px-8"
          >
            Mulai
          </Button>
        </div>
      </div>
    </Card>
  );
}

export default ExamCard;
