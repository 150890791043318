/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import {
  useCallback,
  useContext,
  useMemo,
  useState,
  useRef,
  Fragment,
} from 'react';
import propTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { MathJax } from 'better-react-mathjax';
import ReactTooltip from 'react-tooltip';
import Swal from 'sweetalert2';

import ExamSort from './ExamSort';
import ExamSelect from './ExamSelect';
import ExamCheckBox from './ExamCheckBox';
import ExamMatchV2 from './ExamMatchV2';
import ExamDraw from './ExamDraw';
import ExamFill from './ExamFill';
import ExamVoice from './ExamVoice';
import ExamFinishConfirmModal from './FinishConfirmModal';
import ExamNavigation from './Navigation';
import ExamQuestionNumbers from './QuestionNumbers';
import ExamQuestion from './Question';
import ExamQuestionFill from './QuestionFill';
import ExamDrag from './ExamDrag';
// import ExamPdf from './ExamPdf';
// import ExamAls from './ExamAls';
import ExamScale from './ExamScale';
import ExamQuestionDragPopup from './QuestionPopup';

import Card from 'components/Card';
import Typography from 'components/Typography';
import Button from 'components/Button';
import ExamContext from 'context/exam.context';
// import WebCam from 'components/WebCam';
import {
  // IMAGE_MIME_TYPE,
  EXAM_PACKET_FINISHED,
  EXAM_STATUS_STAGE2,
  EXAM_STATUS_STAGE3,
} from 'constant';
import ExamControllerContext from 'context/examController.context';
import { call } from 'utils/caller';
import QuestionHeader from 'components/Exam/QuestionHeader';
import useBaseURL from 'hooks/useBaseURL';
import clsx from 'libs/clsx';
import { secondsToTimerText } from 'libs/time';

function Exam({ isSimulation, onAnswerChange, onFinish }) {
  const path = useBaseURL();

  /** @type {{ examId: string }} */
  const { examId } = useParams();
  const [exams] = useContext(ExamContext);
  const [
    {
      remainingTime,
      questions,
      answers,
      currentQuestionIndex,
      groupedQuestions,
    },
    dispatch,
  ] = useContext(ExamControllerContext);

  const cardContainerRef = useRef();
  const questionContainerRef = useRef();
  const answerContainerRef = useRef();
  const rightContainerRef = useRef();
  const questionWrapperRef = useRef();
  const answerWrapperRef = useRef();

  const exam = useMemo(
    () =>
      exams.filter(
        (_exam) => String(_exam.paket_ujian.idUj) === String(examId)
      )[0] || {},
    [examId, exams]
  );

  // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  const [isFinishConfirmShow, setIsFinishConfirmShow] = useState(false);

  const answeredQuestions = useMemo(
    () =>
      groupedQuestions
        .map((_questions) =>
          _questions.every((question) => answers[question.index] !== ' ')
        )
        .filter((answered) => answered === true).length,
    [answers, groupedQuestions]
  );

  const areAllQuestionsAnswered = answeredQuestions === groupedQuestions.length;

  // Percentage of process.
  const percentage = useMemo(
    () => Math.floor((answeredQuestions / groupedQuestions.length) * 100),
    [answeredQuestions, groupedQuestions.length]
  );

  const currentGroupedQuestion = groupedQuestions[currentQuestionIndex];

  const currentQuestion = currentGroupedQuestion[0];

  const sanitizedAdditionalQuestion = useMemo(
    () =>
      (currentGroupedQuestion[0].additionalQuestion || '').replace(
        /@url\//g,
        `${path.questionImage}st/`
      ),
    [currentGroupedQuestion, path.questionImage]
  );

  const hasAdditionalQuestion = !!currentGroupedQuestion[0].additionalQuestion;

  const isAdditionalQuestionHasArabic = /[\u0600-\u06FF]/.test(
    currentGroupedQuestion[0].additionalQuestion
  );

  const getExamQuestionRealComponent = (type) => {
    switch (type) {
      case 'pil':
        return ExamQuestion;
      case 'sort':
        return ExamQuestion;
      case 'mlt':
        return ExamQuestion;
      case 'cck':
        return ExamQuestion;
      case 'cvs':
        return ExamQuestion;
      case 'isi':
        return ExamQuestionFill;
      case 'rec':
        return ExamQuestion;
      case 'drag':
        return ExamQuestionDragPopup;
      case 'pdf':
        return ExamQuestion;
      case 'als':
        return ExamQuestion;
      case 'lik':
        return ExamQuestion;
      case 'sjt':
        return ExamQuestion;
      case 'esy':
        return ExamQuestion;
      default:
        return () => <></>;
    }
  };

  // Memilih container berdasarkan jenis pertanyaan.
  const getAnswerContainerComponent = (type) => {
    switch (type) {
      case 'pil':
        return ExamSelect;
      case 'sort':
        return ExamSort;
      case 'mlt':
        return ExamCheckBox;
      case 'cck':
        // return ExamMatch;
        return ExamMatchV2;
      case 'cvs':
        return ExamDraw;
      case 'isi':
        return ExamFill;
      case 'rec':
        return ExamVoice;
      case 'drag':
        return ExamDrag;
      // case 'pdf':
      //   return ExamPdf;
      // case 'als':
      //   return ExamAls;
      case 'lik':
        return ExamScale;
      case 'sjt':
        return ExamScale;
      case 'esy':
        return ExamFill;
      default:
        return () => <></>;
    }
  };

  // Menangani apabila tombol `Sebelumnya` diklik.
  const handlePreviousButtonClick = useCallback(() => {
    questionWrapperRef?.current?.scrollTo(0, 0);
    answerWrapperRef?.current?.scrollTo(0, 0);

    if (currentQuestionIndex > 0) {
      answerContainerRef?.current?.componentWillUnmount();
      call(dispatch.prevQuestion);
      cardContainerRef.current?.scrollTo(0, 0);
    }
  }, [currentQuestionIndex, dispatch.prevQuestion]);

  // Menangani apabila tombol `Selanjutnya` diklik.
  const handleNextButtonClick = useCallback(() => {
    questionWrapperRef?.current?.scrollTo(0, 0);
    answerWrapperRef?.current?.scrollTo(0, 0);

    if (currentQuestionIndex + 1 < questions.length) {
      answerContainerRef?.current?.componentWillUnmount();
      call(dispatch.nextQuestion);
      cardContainerRef.current?.scrollTo(0, 0);
    }
  }, [currentQuestionIndex, dispatch.nextQuestion, questions.length]);

  // Menangani apabila tombol nomor pertanyaan diklik.
  const handleQuestionIndexChange = useCallback(
    (index) => {
      questionWrapperRef?.current?.scrollTo(0, 0);
      answerWrapperRef?.current?.scrollTo(0, 0);
      answerContainerRef?.current?.componentWillUnmount();
      call(dispatch.goToQuestionIndex, index);
    },
    [dispatch.goToQuestionIndex]
  );

  // Menangani apabila jawaban diubah.
  const handleAnswerChange = useCallback(
    (newAnswer) => onAnswerChange(currentQuestion.index, newAnswer),
    [currentQuestion.index, onAnswerChange]
  );

  // Reset answer by " ".
  const handleResetAnswer = useCallback(
    (index) => onAnswerChange(index, ' '),
    [onAnswerChange]
  );

  // Mengupdate pertanyaan.
  const handleRefreshClick = useCallback(
    async ({ done }) => {
      if (!isSimulation) {
        await call(dispatch.refreshCurrentQuestion);
      }

      done();
    },
    [dispatch.refreshCurrentQuestion, isSimulation]
  );

  // TODO: fix it
  const handleFinishButtonClick = useCallback(() => {
    if (!areAllQuestionsAnswered) {
      Swal.fire(
        'Semua pertanyaan harus dijawab terlebih dahulu',
        '',
        'warning'
      );
      return;
    }

    const uncompleteMltQuestions = questions
      .filter((question) => question.type === 'mlt')
      .filter((question) => {
        const maxSelectedAnswer = question.maxChecked;
        const selectedAnswer = (answers[question.index] || '')
          .split('')
          .filter((str) => str !== ' ').length;

        return selectedAnswer !== maxSelectedAnswer;
      });

    if (uncompleteMltQuestions.length > 0) {
      const questionNumbers = uncompleteMltQuestions
        .map((question) => question.index + 1)
        .join(', ')
        .replace(/, ([^,]*)$/, ' dan $1');
      Swal.fire(
        `Pertanyaan nomor ${questionNumbers} harus dilengkapi terlebih dahulu`,
        '',
        'warning'
      );
      return;
    }

    // if (['sort', 'cvs'].includes(currentQuestion.type)) {
    //   answerContainerRef?.current?.componentWillUnmount();
    // }
    setIsFinishConfirmShow(true);
  }, [answers, areAllQuestionsAnswered, questions]);

  const handleFinishConfirmCancel = useCallback(
    () => setIsFinishConfirmShow(false),
    []
  );

  const handleFinishConfirmed = useCallback(() => {
    onFinish();
  }, [onFinish]);

  const ZOOM_DEFAULT = 1;
  const ZOOM_MIN = 0.5;
  const ZOOM_MAX = 2.0;
  const ZOOM_STEP = 0.1;

  const [zoomLevel, setZoomLevel] = useState(ZOOM_DEFAULT);

  const zoomChange = (newZoom) => {
    document.body.style.zoom = newZoom;
  };

  const handleZoomIn = () => {
    const newZoomLevel = Math.min(zoomLevel + ZOOM_STEP, ZOOM_MAX);
    setZoomLevel(newZoomLevel);
    zoomChange(newZoomLevel);
  };

  const handleZoomOut = () => {
    const newZoomLevel = Math.max(zoomLevel - ZOOM_STEP, ZOOM_MIN);
    setZoomLevel(newZoomLevel);
    zoomChange(newZoomLevel);
  };

  const handleResetZoom = () => {
    setZoomLevel(ZOOM_DEFAULT);
    zoomChange(ZOOM_DEFAULT);
  };

  return (
    <>
      <div className="grid grid-cols-12 gap-4 xl:h-screen py-4">
        <div
          ref={cardContainerRef}
          className="2xl:col-span-9 xl:col-span-9 col-span-12 xl:h-full h-screen overflow-y-auto"
        >
          <Card
            ref={questionContainerRef}
            className="flex flex-col h-full question-container"
            style={{ maxHeight: '95vh' }}
            compact
          >
            <QuestionHeader
              isSimulation={isSimulation}
              currentQuestionIndex={currentQuestionIndex}
              questionTotal={groupedQuestions.length}
              onRefresh={handleRefreshClick}
              examNavigation={
                <ExamNavigation
                  currentQuestionIndex={currentQuestionIndex}
                  remainingTime={remainingTime}
                  percentage={percentage}
                  questionTotal={groupedQuestions.length}
                  answeredQuestionTotal={answeredQuestions}
                  onPreviousButtonClick={handlePreviousButtonClick}
                  onNextButtonClick={handleNextButtonClick}
                  style={{
                    left: questionContainerRef?.current?.getBoundingClientRect()
                      .left,
                    width:
                      questionContainerRef?.current?.getBoundingClientRect()
                        .width,
                    visibility: questionContainerRef.current
                      ? 'visible'
                      : 'hidden',
                  }}
                />
              }
            />

            <div
              className={clsx(
                'shrink-0 overflow-hidden grid',
                hasAdditionalQuestion
                  ? 'lg:grid-cols-2 lg:gap-10 grid-cols-1'
                  : 'grid-cols-1'
              )}
            >
              {/* Paragraf tambahan */}
              {hasAdditionalQuestion && (
                <div ref={questionWrapperRef} className="overflow-y-auto">
                  <Typography
                    as="div"
                    className={clsx(
                      'stimulus-text',
                      'font-readable select-none',
                      isAdditionalQuestionHasArabic && 'xl:text-3xl text-2xl'
                    )}
                    dir="auto"
                    style={{
                      fontFamily: isAdditionalQuestionHasArabic
                        ? 'Traditional Arabic'
                        : 'inherit',
                    }}
                    gutterBottom
                  >
                    <MathJax>
                      <span
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: sanitizedAdditionalQuestion,
                        }}
                      />
                    </MathJax>
                  </Typography>
                </div>
              )}

              <div ref={answerWrapperRef} className="overflow-y-auto pr-2">
                {currentGroupedQuestion.map((question, index) => {
                  const ExamQuestionReal = getExamQuestionRealComponent(
                    question.type
                  );
                  const AnswerContainer = getAnswerContainerComponent(
                    question.type
                  );

                  return (
                    <Fragment key={index}>
                      {index > 0 && <hr className="my-5" />}

                      {/* Pertanyaan */}
                      <ExamQuestionReal
                        question={question}
                        answer={answers[question.index]}
                        onAnswerChange={handleAnswerChange}
                      />

                      {/* Jawaban container */}
                      {question.type !== 'isi' && question.type !== 'drag' && (
                        <div className="mt-7">
                          <AnswerContainer
                            ref={answerContainerRef}
                            question={question}
                            answer={answers[question.index]}
                            onAnswerChange={handleAnswerChange}
                            resetAnswer={handleResetAnswer}
                          />
                        </div>
                      )}
                    </Fragment>
                  );
                })}
              </div>
            </div>
          </Card>
        </div>

        {/* Right side container */}
        <div
          className="2xl:col-span-3 xl:col-span-3 col-span-12 gap-4 xl:h-full h-screen"
          ref={rightContainerRef}
          style={{
            maxHeight: '95vh',
          }}
        >
          {/* Card jumlah soal */}
          <Card
            className="pt-4 h-full"
            // style={{
            //   maxHeight: isTabletOrMobile ? 'auto' : '50%',
            // }}
          >
            <div className="flex flex-col gap-2 h-full">
              <div className="flex-none space-y-3 pb-2">
                <div className="flex flex-wrap items-center justify-center gap-2">
                  <Button
                    type="button"
                    size="xs"
                    color="secondary"
                    className="shadow-none"
                    onClick={handleZoomIn}
                  >
                    <span className="flex items-center gap-1">
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.5 9.5C7.70914 9.5 9.5 7.70914 9.5 5.5C9.5 3.29086 7.70914 1.5 5.5 1.5C3.29086 1.5 1.5 3.29086 1.5 5.5C1.5 7.70914 3.29086 9.5 5.5 9.5Z"
                          stroke="black"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.5 10.5L8.32501 8.32501"
                          stroke="black"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M5.5 4V7"
                          stroke="black"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4 5.5H7"
                          stroke="black"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Perbesar
                    </span>
                  </Button>
                  <Button
                    type="button"
                    size="xs"
                    color="secondary"
                    className="shadow-none"
                    onClick={handleZoomOut}
                  >
                    <span className="flex items-center gap-1">
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.5 9.5C7.70914 9.5 9.5 7.70914 9.5 5.5C9.5 3.29086 7.70914 1.5 5.5 1.5C3.29086 1.5 1.5 3.29086 1.5 5.5C1.5 7.70914 3.29086 9.5 5.5 9.5Z"
                          stroke="black"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.5 10.5L8.32501 8.32501"
                          stroke="black"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4 5.5H7"
                          stroke="black"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Perkecil
                    </span>
                  </Button>
                  <Button
                    type="button"
                    size="xs"
                    color="secondary"
                    className="shadow-none"
                    onClick={handleResetZoom}
                  >
                    <span className="flex items-center gap-1">
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.5 6C10.5 6.89002 10.2361 7.76004 9.74162 8.50007C9.24715 9.24009 8.54434 9.81686 7.72208 10.1575C6.89981 10.4981 5.99501 10.5872 5.1221 10.4135C4.24918 10.2399 3.44736 9.81132 2.81802 9.18198C2.18869 8.55264 1.7601 7.75082 1.58647 6.87791C1.41283 6.00499 1.50195 5.10019 1.84254 4.27792C2.18314 3.45566 2.75991 2.75285 3.49994 2.25839C4.23996 1.76392 5.10999 1.5 6 1.5C7.26 1.5 8.465 2 9.37 2.87L10.5 4"
                          stroke="black"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.5 1.5V4H8"
                          stroke="black"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Reset
                    </span>
                  </Button>
                </div>

                <div className="text-center px-5">
                  <Typography variant="small" color="muted">
                    Sisa Waktu
                  </Typography>
                  <Typography variant="subtitle1">
                    {secondsToTimerText(remainingTime)}
                  </Typography>
                </div>

                <div className="flex flex-col gap-3">
                  {/* Reset answer button */}
                  <Button
                    size="custom"
                    type="button"
                    color="danger"
                    onClick={() => handleResetAnswer(currentQuestion.index)}
                    disabled={answers[currentQuestion.index] === ' '}
                    block
                  >
                    Batalkan Jawaban
                  </Button>

                  {/* Finish button */}
                  <Button
                    size="custom"
                    type="button"
                    color="warning"
                    onClick={handleFinishButtonClick}
                    data-tip={
                      !areAllQuestionsAnswered &&
                      'Semua pertanyaan harus dijawab terlebih dahulu'
                    }
                    block
                  >
                    {isSimulation && 'Selesai'}
                    {!isSimulation &&
                      (parseInt(exam.paket_ujian.sta, 10) + 1 ===
                      EXAM_PACKET_FINISHED
                        ? 'Selesai'
                        : 'Selesai & Lanjutkan')}
                  </Button>

                  {!areAllQuestionsAnswered && <ReactTooltip />}
                </div>
              </div>

              <div className="flex items-center justify-between">
                {/* Title */}
                <Typography
                  variant="subtitle2"
                  className="flex-none font-medium"
                >
                  Peta Soal
                </Typography>
                <Typography
                  as="span"
                  variant="small"
                  className="font-normal px-5 py-1 bg-yellow-200 text-yellow-700 rounded-full"
                >
                  {isSimulation && 'Simulasi'}
                  {!isSimulation &&
                    (exam.paket_ujian?.sta === EXAM_STATUS_STAGE3
                      ? 'Stage 3'
                      : exam.paket_ujian?.sta === EXAM_STATUS_STAGE2
                      ? 'Stage 2'
                      : 'Stage 1')}
                </Typography>
              </div>

              <div className="relative flex-grow overflow-y-auto">
                {/* Question number list */}
                <ExamQuestionNumbers
                  currentQuestionIndex={currentQuestionIndex}
                  questions={questions}
                  groupedQuestions={groupedQuestions}
                  answers={answers}
                  onNumberButtonClick={handleQuestionIndexChange}
                />
              </div>
            </div>
          </Card>

          {/* Webcam */}
          {/* <div
            className="flex-none xl:block hidden"
            style={{
              minHeight: isTabletOrMobile ? '60%' : '40%',
            }}
          /> */}
          {/* <Card
            className="flex-none"
            style={{
              minHeight: isTabletOrMobile ? '60%' : '40%',
            }}
          >
            <WebCam
              audio={false}
              controls={false}
              screenshotFormat={IMAGE_MIME_TYPE}
            />
          </Card> */}
        </div>
      </div>

      {/* Finish confirmation modal */}
      <ExamFinishConfirmModal
        isOpen={isFinishConfirmShow}
        onClose={handleFinishConfirmCancel}
        onConfirm={handleFinishConfirmed}
      />

      <style jsx="true">
        {`
          .stimulus-text p {
            padding-bottom: 10px;
          }
        `}
      </style>
    </>
  );
}

Exam.defaultProps = {
  isSimulation: false,
};

Exam.propTypes = {
  isSimulation: propTypes.bool,
};

export default Exam;
