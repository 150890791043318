/* eslint-disable react/prop-types */
import { ArrowContainer } from 'react-tiny-popover';

import Button from 'components/Button';
import Typography from 'components/Typography';
import * as examHelper from 'utils/exam';
import { safeJoinURL } from 'libs/url';
import useBaseURL from 'hooks/useBaseURL';
import clsx from 'libs/clsx';

const Popup = ({
  position,
  childRect,
  popoverRect,
  choices,
  onAnswerChange,
}) => {
  const path = useBaseURL();

  return (
    <ArrowContainer
      position={position}
      childRect={childRect}
      popoverRect={popoverRect}
      arrowSize={10}
      className="popover-arrow-container"
      arrowClassName="bg-dark"
    >
      <div className="bg-white shadow-lg border-2 border-gray-200 p-2.5 w-64 max-h-screen overflow-y-auto">
        <div className="flex flex-col gap-4">
          {choices
            .map((choice, index) =>
              // Hanya menempilkan pilihan jawaban yang isinya bukan null, karena
              // jika null berarti pilihan jawaban tersebut telah digunakan untuk
              // menjawab pertanyaan lain
              choice !== null ? (
                <Button
                  key={index}
                  type="button"
                  onClick={() => onAnswerChange(index)}
                  block
                >
                  {examHelper.isImage(choice) ? (
                    <img
                      className="h-full w-auto max-w-full max-h-24"
                      src={safeJoinURL(path.questionImage, choice)}
                      alt={choice}
                    />
                  ) : (
                    <Typography
                      as="div"
                      className={clsx(
                        'font-semibold',
                        /[\u0600-\u06FF]/.test(choice) && 'arabic'
                      )}
                      dangerouslySetInnerHTML={{ __html: choice }}
                    />
                  )}
                </Button>
              ) : null
            )
            .filter((choice) => choice !== null)}

          {/* Reset button */}
          <Button
            type="button"
            color="secondary"
            onClick={() => onAnswerChange(null)}
          >
            Reset
          </Button>
        </div>
      </div>
    </ArrowContainer>
  );
};

export default Popup;
