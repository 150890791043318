import propTypes from 'prop-types';

import clsx from 'libs/clsx';

function Progress({ percentage, className }) {
  return (
    <div className={clsx('relative', className)}>
      <div className="overflow-hidden h-2 text-xs flex rounded bg-green-100">
        <div
          style={{
            width: `${percentage}%`,
          }}
          className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"
        />
      </div>
    </div>
  );
}

Progress.defaultProps = {
  className: '',
};

Progress.propTypes = {
  className: propTypes.string,
  percentage: propTypes.number.isRequired,
};

export default Progress;
