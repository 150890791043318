import propTypes from 'prop-types';
import { useState, useCallback, useEffect, useContext } from 'react';

import SelectServerModal from 'components/SelectServerModal';
import Typography from 'components/Typography';
import { SECOND } from 'constant';
import logo from 'assets/images/logo.png';
import uinsa from 'assets/images/uinsa.png';
import AppContext from 'context/app.context';
import { setBackendURL } from 'actions/app.action';

const SPLASH_SCREEN_DURATION = 3 * SECOND;

// Backend URL yang tersimpan.
const storedBackendURL = localStorage.getItem('backendURL');

function Splash({ children }) {
  const [{ backendURL }, dispatchApp] = useContext(AppContext);

  // Sedang dalam splash screen. `splashScreenOn` akan selalu bernilai true
  // jika halaman pertama kali dimuat.
  const [splashScreenOn, setSplashScreenOn] = useState(true);

  // Masa dari splashscreen, akan bernilai true saat 3 detik setelah dimuat.
  const [splashScreenOver, setSplashScreenOver] = useState(false);

  // Handle change server.
  const handleServerChange = useCallback(
    (_domain) => {
      dispatchApp(setBackendURL(_domain));
      setSplashScreenOn(false);
    },
    [dispatchApp]
  );

  // Menampilkan form input domain setelah 3 detik atau men-set nilai dari
  // domain ke context.
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (storedBackendURL) handleServerChange(storedBackendURL);
      setSplashScreenOver(true);
    }, SPLASH_SCREEN_DURATION);

    return () => clearTimeout(timeout);
  }, [handleServerChange]);

  // Menghilangkan splash screen saat backend url memiliki nilai.
  useEffect(() => {
    if (splashScreenOver && backendURL) setSplashScreenOn(false);
  }, [backendURL, splashScreenOver]);

  return (
    <>
      {splashScreenOn ? (
        <>
          {/* Splash screen logo */}
          <div className="h-screen flex flex-wrap content-center">
            <div className="w-full text-center">
              <img
                src={logo}
                className="w-full h-auto mx-auto"
                style={{
                  maxWidth: '200px',
                }}
                alt="logo"
              />
              <Typography
                variant="heading3"
                className="font-normal text-primary mt-10"
              >
                Ujian CBT AKMI
              </Typography>
              <Typography variant="subtitle2" gutterBottom className="mt-4">
                Versi {process.env.REACT_APP_VERSION}
              </Typography>
              <Typography variant="extra-small" className="text-center mt-10">
                Copyright&copy; Kementerian Agama RI
              </Typography>

              <img
                src={uinsa}
                className="w-full h-auto mx-auto pt-5"
                style={{
                  maxWidth: '70px',
                }}
                alt="copyright"
              />
            </div>
          </div>
        </>
      ) : (
        children
      )}

      <SelectServerModal
        isOpen={splashScreenOver && !backendURL}
        onChange={handleServerChange}
      />
    </>
  );
}

Splash.propTypes = {
  children: propTypes.node.isRequired,
};

export default Splash;
