import propTypes from 'prop-types';
import { createContext, useReducer } from 'react';

import permissionReducer, {
  initialPermissionState,
} from 'reducers/permission.reducer';

const PermissionContext = createContext();

export function PermissionContextProvider({ children }) {
  return (
    <PermissionContext.Provider
      value={useReducer(permissionReducer, initialPermissionState)}
    >
      {children}
    </PermissionContext.Provider>
  );
}

PermissionContextProvider.propTypes = {
  children: propTypes.node.isRequired,
};

export default PermissionContext;
