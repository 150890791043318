/* eslint-disable react/prop-types */
import Button from 'components/Button';
import Progress from 'components/Progress';
import Typography from 'components/Typography';
import clsx from 'libs/clsx';

function ExamNavigation({
  percentage,
  onPreviousButtonClick,
  onNextButtonClick,
  currentQuestionIndex,
  answeredQuestionTotal,
  questionTotal,
}) {
  return (
    <div>
      <div className="flex justify-between items-center">
        {/* Previous button */}
        <Button
          type="button"
          size="sm"
          color="primary-outlined"
          onClick={onPreviousButtonClick}
          className={clsx(currentQuestionIndex === 0 && 'invisible')}
        >
          Sebelumnya
        </Button>

        {/* Question number */}
        <div>
          <Typography variant="subtitle1" className="font-medium text-center">
            <span className="text-gray-400">Soal</span>{' '}
            {currentQuestionIndex + 1}{' '}
            <span className="text-gray-400">dari</span> {questionTotal}{' '}
          </Typography>
          <Typography variant="extra-small" className="text-center">
            <span className="text-gray-600">Terjawab </span>
            {answeredQuestionTotal} <span className="text-gray-600">dari</span>{' '}
            {questionTotal}{' '}
            <span className="text-green-600 font-semibold">
              ({percentage}%)
            </span>
          </Typography>
        </div>

        {/* Next button */}
        <Button
          type="button"
          size="sm"
          onClick={onNextButtonClick}
          className={clsx(
            currentQuestionIndex + 1 === questionTotal && 'invisible'
          )}
        >
          Selanjutnya
        </Button>
      </div>

      {/* Percentage of progress */}
      <div className="mt-2">
        <Progress percentage={percentage} />
      </div>
    </div>
  );
}

export default ExamNavigation;
