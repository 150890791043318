import { useContext } from 'react';
import propTypes from 'prop-types';

import Sidebar from './Sidebar';

import AppContext from 'context/app.context';
import clsx from 'libs/clsx';

function DashboardContainer({
  activeSidebarItem,
  showProfileOnSidebar,
  children,
}) {
  const [{ isSidebarOpen }] = useContext(AppContext);

  return (
    <>
      <div
        className={clsx(
          'min-h-screen mx-4 transition-all duration-300 ease-in-out',
          isSidebarOpen ? '2xl:pl-80 xl:pl-72 pl-64' : 'pl-0'
        )}
      >
        {children}
      </div>

      <Sidebar
        activeItem={activeSidebarItem}
        showProfileCard={showProfileOnSidebar}
      />
    </>
  );
}

DashboardContainer.defaultProps = {
  showProfileOnSidebar: false,
};

DashboardContainer.propTypes = {
  activeSidebarItem: propTypes.string.isRequired,
  showProfileOnSidebar: propTypes.bool,
  children: propTypes.node.isRequired,
};

export default DashboardContainer;
