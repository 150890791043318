/* eslint-disable import/no-extraneous-dependencies */
import 'firebase/messaging';

import firebase from 'firebase/app';

import configuration from 'configuration';

// eslint-disable-next-line import/no-mutable-exports
let messaging;

try {
  messaging = firebase.messaging();
} catch (err) {
  // eslint-disable-next-line no-console
  console.log(err);
}
// firebase.initializeApp(configuration.FIREBASE);

firebase.initializeApp(configuration.FIREBASE);
// const messaging = firebase.messaging();

export default messaging;
