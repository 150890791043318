/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import { forwardRef, memo, useCallback, useMemo } from 'react';
import colors from 'tailwindcss/colors';
import { MathJax } from 'better-react-mathjax';

import clsx from 'libs/clsx';
import {
  getAlphabetByIndex,
  getIndexByAlphabet,
  shuffleChoices,
} from 'utils/exam';
// import * as examHelper from 'utils/exam';
import useBaseURL from 'hooks/useBaseURL';
// import { safeJoinURL } from 'libs/url';
import Typography from 'components/Typography';

function ExamCheckBox(
  { question: { id, choices, maxChecked }, answer, onAnswerChange },
  ref // eslint-disable-line no-unused-vars
) {
  const path = useBaseURL();

  const [shuffledChoices, shuffledIndexes] = useMemo(
    () => shuffleChoices(choices),
    [choices]
  );

  /**
   * Jawaban yang dipilih peserta.
   * Berbentuk boolean dalam array, jika true berarti jawabannya dipilih.
   * @type {Array<boolean>}
   */
  const values = useMemo(() => {
    const answerArray = choices.map(() => false);

    if (answer === ' ') return answerArray;

    answer.split('').forEach((item) => {
      const index = getIndexByAlphabet(item);
      if (index > -1 && index + 1 <= answerArray.length) {
        answerArray[index] = true;
      }
    });

    return answerArray;
    // Mereset value ketika nomor pertanyaan berubah.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, answer, choices]);

  const isReachedMaxChecked = useMemo(
    () => values.filter((value) => value).length >= maxChecked,
    [maxChecked, values]
  );

  // Menangani apabila peserta menjawab atau mengubah jawaban.
  const handleChange = useCallback(
    (index, isChecked) => {
      // Tidak bisa memilih lebih dari maxChecked.
      if (isChecked && isReachedMaxChecked) return;

      const realIndex = shuffledIndexes[index];

      const newAnswer = [...values];
      newAnswer[realIndex] = isChecked;

      const answerString = newAnswer
        .map((value, _index) => {
          if (value) return getAlphabetByIndex(_index);
          return '';
        })
        .join('');

      onAnswerChange(answerString === '' ? ' ' : answerString);
    },
    [isReachedMaxChecked, onAnswerChange, shuffledIndexes, values]
  );

  return (
    <>
      {shuffledChoices.map((choice, index) => {
        const choiceId = `choice-${id}-${index}`;
        // const alphabetLabel = getAlphabetByIndex(index);
        const realIndex = shuffledIndexes[index];
        const isChecked = values[realIndex] === true;
        const questionArabic = /[\u0600-\u06FF]/.test(choice);

        const handleChangeByDiv = () => {
          handleChange(index, !isChecked);
        };
        //  cek
        const tempChoiceString = choice.replace(
          /@url\//g,
          `${path.questionImage}c/`
        );

        return (
          <div
            key={choice}
            className={clsx(
              'answer-container',
              'flex items-center border-2 border-gray-100 rounded-lg p-2.5 my-3 shadow cursor-pointer select-none',
              isChecked && 'border-2 border-green-400',
              !isChecked && isReachedMaxChecked && 'opacity-50'
            )}
            onClick={handleChangeByDiv}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
          >
            <input
              type="checkbox"
              id={choiceId}
              name={choiceId}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => handleChange(index, e.target.checked)}
              checked={isChecked}
              className="h-4 w-4 text-gray-700 px-3 py-3 border rounded mr-2 cursor-pointer"
            />
            <label
              htmlFor={choiceId}
              className="cursor-pointer ml-1"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex items-center gap-2">
                {/* <Typography className="font-normal">
                  {alphabetLabel}.
                </Typography> */}
                <Typography
                  as="div"
                  variant={questionArabic ? 'heading3' : 'paragraph'}
                  className="font-normal"
                  style={{
                    fontFamily: questionArabic
                      ? 'Traditional Arabic'
                      : 'inherit',
                  }}
                >
                  <MathJax>
                    <span
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: tempChoiceString }}
                    />
                  </MathJax>
                </Typography>
              </div>
            </label>
          </div>
        );
      })}

      <style jsx="true">{`
        input[type='checkbox'] {
          -webkit-appearance: none; /*hides the default checkbox*/
          height: 20px;
          width: 20px;
          transform: scale(0.75);
          position: relative;
          text-align: center;
          font-weight: 600;
          font-size: 18px;
          border: 2px solid ${colors.gray['400']};
          outline: none;
        }

        input[type='checkbox']:checked {
          color: white;
          background-color: #00a65a;
          border: 2px solid transparent;
        }

        input[type='checkbox']:checked:before {
          position: absolute;
          content: '✔';
          top: 0;
          left: 20%;
        }

        input[type='checkbox']:hover {
          cursor: pointer;
        }
      `}</style>
    </>
  );
}

ExamCheckBox = forwardRef(ExamCheckBox);

export default memo(ExamCheckBox);
