import propTypes from 'prop-types';

import clsx from 'libs/clsx';
import Typography from 'components/Typography';

const COLORS = {
  success: 'bg-green-100 border-green-500 text-green-800',
  info: 'bg-blue-100 border-blue-500 text-blue-900',
  warning: 'bg-yellow-100 border-yellow-500 text-yellow-800',
  danger: 'bg-red-200 border-red-500 text-red-900',
};

function Alert({ title, color, withBorder, className, children }) {
  return (
    <div
      className={clsx(
        'relative px-4 py-5',
        COLORS[color],
        withBorder && 'border-l-4',
        className
      )}
    >
      {title && (
        <Typography variant="subtitle3" className="font-semibold">
          {title}
        </Typography>
      )}
      <Typography as="div" className="font-normal">
        {children}
      </Typography>
    </div>
  );
}

Alert.defaultProps = {
  title: null,
  color: 'success',
  className: '',
  withBorder: false,
};

Alert.propTypes = {
  title: propTypes.oneOfType([propTypes.string, propTypes.node]),
  color: propTypes.oneOf(['success', 'warning', 'info', 'danger']),
  className: propTypes.string,
  withBorder: propTypes.bool,
  children: propTypes.node.isRequired,
};

export default Alert;
