/* eslint-disable react/prop-types */
import { useCallback, useEffect, useRef, useState } from 'react';

import { Modal, ModalContent } from 'components/Modal';
import Alert from 'components/Alert';
import Button from 'components/Button';
import Input from 'components/Input';
import Typography from 'components/Typography';
import { ReactComponent as GetOutIllustration } from 'assets/illustrations/get-out.svg';
import { SECOND } from 'constant';

function ExamFinishConfirmModal({ isOpen, onClose, onConfirm }) {
  const [text, setText] = useState('');
  const [isValidText, setIsValidText] = useState(false);
  const [loading, setLoading] = useState(false);

  // Agar loading otomatis berhenti setelah beberapa detik.
  const loadingTimeout = useRef();

  const handleTextChange = useCallback((e) => {
    const { value } = e.target;
    setText(value);

    if (/^selesai$/i.test(value)) setIsValidText(true);
    else setIsValidText(false);
  }, []);

  const handleConfirmClick = () => {
    clearTimeout(loadingTimeout.current);
    loadingTimeout.current = setTimeout(() => setLoading(false), 5 * SECOND);
    setLoading(true);

    onConfirm();
  };

  useEffect(() => () => clearTimeout(loadingTimeout.current), []);

  return (
    <Modal isOpen={isOpen} size="sm">
      <ModalContent className="px-6 py-9">
        <GetOutIllustration className="mx-auto -mt-4" />

        <Typography
          variant="subtitle1"
          className="font-normal text-center"
          gutterBottom
        >
          Mau selesaikan ujian?
        </Typography>

        <Typography
          variant="paragraph"
          color="muted"
          className="mt-5"
          gutterBottom
        >
          Pastikan kamu telah mengoreksi kembali jawaban kamu ya sebelum
          menyelesaikan ujian. Ketik kata <strong>SELESAI</strong> terlebih
          dahulu untuk menyelesaikan ujian ini.
        </Typography>

        <Alert color="warning">
          Ujian yang telah diselesaikan&nbsp;
          <strong>TIDAK DAPAT</strong> dimulai atau dilanjutkan kembali!
        </Alert>

        <Input
          type="text"
          name="confirm"
          label=""
          id="confirm"
          placeholder="Ketik di sini..."
          className="mt-2"
          value={text}
          onChange={handleTextChange}
          autoFocus
        />

        {/* Action buttons */}
        <div className="flex justify-center gap-4 mt-4">
          {/* Cancel button */}
          <Button color="danger-outlined" onClick={onClose} block>
            Tetap di ruangan
          </Button>

          {/* Confirm button */}
          <Button
            color="danger"
            onClick={handleConfirmClick}
            loading={loading}
            disabled={!isValidText || loading}
            block
          >
            Selesaikan
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
}

export default ExamFinishConfirmModal;
