import axios from 'axios';

import {
  SET_CAMERA_ID,
  SET_BACKEND_URL,
  UNSET_BACKEND_URL,
  SET_CONFIG,
  TOGGLE_SIDEBAR,
  MODAL_IMAGE_OPEN,
  MODAL_IMAGE_CLOSE,
} from 'constant';

/**
 * @typedef   {Object}            AppState
 *
 * @property  {string|undefined}  cameraId
 *
 * @property  {string|undefined}  title_ujian
 * @property  {string|undefined}  des_ujian
 *
 * @property  {integer|undefined} timerFoto
 * @property  {integer|undefined} timerUploadAnswer
 * @property  {integer|undefined} timeCheckSecurity
 *
 * @property  {string|undefined}  backendURL
 * @property  {string|undefined}  profilePhotoBaseURL
 * @property  {string|undefined}  selfiePhotoBaseURL
 * @property  {string|undefined}  answerWithAudioBaseURL
 * @property  {string|undefined}  answerWithImageBaseURL
 *
 * @property  {boolean}           isSidebarOpen
 * @property  {boolean}           isModalImageOpen
 * @property  {string|null}       modalImageUrl
 */

/**
 * @type {AppState}
 */
export const initialAppState = {
  cameraId: undefined,

  title_ujian: undefined,
  des_ujian: undefined,

  timerFoto: undefined,
  timerUploadAnswer: undefined,
  timeCheckSecurity: undefined,
  timeCheckNotif: undefined,

  backendURL: localStorage.getItem('backendURL') || undefined,
  profilePhotoBaseURL: undefined,
  selfiePhotoBaseURL: undefined,
  answerWithAudioBaseURL: undefined,
  answerWithImageBaseURL: undefined,

  isSidebarOpen: true,
  isModalImageOpen: false,
  modalImageUrl: undefined,
};

export default function examReducer(state = initialAppState, action) {
  switch (action.type) {
    case SET_CAMERA_ID: {
      return { ...state, cameraId: action.payload };
    }
    case SET_BACKEND_URL: {
      axios.defaults.baseURL = action.payload;
      localStorage.setItem('backendURL', action.payload);
      return { ...state, backendURL: action.payload };
    }
    case UNSET_BACKEND_URL: {
      axios.defaults.baseURL = null;
      localStorage.removeItem('backendURL');
      return { ...state, backendURL: undefined };
    }
    case SET_CONFIG: {
      return { ...state, ...action.payload };
    }
    case TOGGLE_SIDEBAR: {
      return { ...state, isSidebarOpen: !state.isSidebarOpen };
    }
    case MODAL_IMAGE_OPEN: {
      return {
        ...state,
        isModalImageOpen: true,
        modalImageUrl: action.payload,
      };
    }
    case MODAL_IMAGE_CLOSE: {
      return {
        ...state,
        isModalImageOpen: false,
        modalImageUrl: null,
      };
    }
    default: {
      console.error(`Unhandled action type: ${action.type}`);
      return state;
    }
  }
}
