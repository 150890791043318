/* eslint-disable react/prop-types */
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as SupportIcon } from 'assets/illustrations/support.svg';
import Button from 'components/Button';
import { Modal, ModalContent } from 'components/Modal';
import clsx from 'libs/clsx';
import Typography from 'components/Typography';

function QuestionAnsweredModal({
  isOpen,
  isAuthenticated,
  title,
  text,
  onClose,
}) {
  const history = useHistory();

  const handleShowAnswerButtonClick = useCallback(() => {
    history.push('/dashboard/bantuan');
    onClose();
  }, [history, onClose]);

  return (
    <Modal isOpen={isOpen} size="sm">
      <ModalContent className="pt-0 pb-6 py-9 text-center">
        <SupportIcon className="mx-auto w-full max-w-xs h-auto" />

        <Typography
          variant="subtitle1"
          className="text-gray-800 font-medium"
          gutterBottom
        >
          {title}
        </Typography>

        <Typography className="font-normal text-gray-400 mt-3" gutterBottom>
          {text}
        </Typography>

        <div
          className={clsx('mt-8', isAuthenticated && 'grid grid-cols-2 gap-3 ')}
        >
          {isAuthenticated && (
            <Button onClick={handleShowAnswerButtonClick} block>
              Lihat Jawaban
            </Button>
          )}
          <Button color="secondary" onClick={onClose} block>
            Tutup
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
}

export default QuestionAnsweredModal;
